import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GroupProduct from "../pages/products/groupProduct/GroupProduct";
import CategoryProduct from "../pages/products/categoryProduct/CategoryProductPage";
import PrivateRoute from "./privaterouters";
import TagProduct from "../pages/products/tagProduct/TagProduct";
import Brand from "../pages/products/brand/Brand";
import Login from "../pages/login/Login";
import Coupon from "../pages/products/coupon/Coupon";
import Attribute from "../pages/products/attribute/Attribute";
import EditProductGeneral from "../pages/products/product/editProduct/EditProductGeneralPage";
import EditProductVariant from "../pages/products/product/editProduct/EditProductVariantPage";
import EditProductBarcode from "../pages/products/product/editProduct/EditProductBarcodePage";
import EditProductQrcode from "../pages/products/product/editProduct/EditProductQrcodePage";
import EditProductImage from "../pages/products/product/editProduct/EditProductImagePage";
import Label from "../pages/products/label/Label";
import Info from "../pages/user/info/Info";
import Inventory from "../pages/products/inventory/Inventory";
import Order from "../pages/order/Order";
import EditOrder from "../pages/order/components/EditOrder";
import PrintOrder from "../pages/order/components/PrintOrder";
import Room from "../pages/room/RoomPage";
import CreateProduct from "../pages/products/product/createProduct/CreateProductPage";
import ProductPage from "../pages/products/product/ProductPage";
import DetailOrderPosPage from "../pages/fnb/detailOrderPos/DetailOrderPosPage";
import ViewDetailOrderPosPage from "../pages/fnb/detailOrderPos/ViewDetailOrderPosPage";
import OrdersSessionMachinePage from "../pages/fnb/orderSessionMachine/OrdersSessionMachinePage";
import DashBoard from "../pages/dashboard/DashBoardPage";
import ProductInPosPage from "../pages/fnb/productInPos/ProductInPosPage";
import StoreBranchPage from "../pages/settings/storebranch/StoreBranchPage";
import DetailMachinePosPage from "../pages/fnb/detailMachinePos/DetailMachinePosPage";
import SalePointPage from "../pages/fnb/SalePointPage";
import StoreBranchModal from "../pages/settings/storebranch/StoreBranchModal";
import PaymentPosPage from "../pages/fnb/paymentPos/PaymentPosPage";
import PaymentSuccessPage from "../pages/fnb/paymentSuccess/PaymentSuccessPage";
import CustomerDebtPage from "../pages/fnb/debt/listCustomerDebt/CustomerDebtPage";
import PaymentDebtPage from "../pages/fnb/debt/paymentDebt/PaymentDebtPage";
import RefundOrderPage from "../pages/fnb/refund/RefundOrderPage";
import RefundOrderDetailsPage from "../pages/fnb/refund/RefundOrderDetailsPage";
import PaymentDebtSuccessPage from "../pages/fnb/debt/paymentDebtSuccess/PaymentDebtSuccessPage";
import CustomerDebtDetailPage from "../pages/fnb/debt/CustomerDebtDetail/CustomerDebtDetailPage";
import RefundPaymentPage from "../pages/fnb/refund/refundPayment/RefundPaymentPage";
import AdminPage from "../pages/settings/admin/AdminPage";
import { checkPermission } from "../common/utils/Utils";
import NoAccess from "../shared/components/NoAccess";
import { permissionCode } from "../common/constants/Constants";
import PermissionsPage from "../pages/settings/permissions/PermissionsPage";
import PaymentsPage from "../pages/settings/payments/PaymentsPage";
import FilesPage from "../pages/settings/files/FilesPage";
import PermissionsEditPage from "../pages/settings/permissions/PermissionsEditPage";
import Pos from "../pages/settings/pos/Pos";
import DiningTablesPage from "../pages/settings/pos/diningTables/DiningTablesPage";
import ConfigMailPage from "../pages/settings/mail/configMail/ConfigMailPage";
import ForgotPass from "../pages/forgotPass/ForgotPass";
import CrmIntegrationPage from "../pages/settings/crm_Integration/CrmIntegrationPage";
import SpacesFloorRoomTable from "../pages/fnb/spacesFloorRoomTable/SpacesFloorRoomTablePage";
import SeparationPage from "../pages/fnb/separationOrder/separation/SeparationPage";
import PaymentSeparationPage from "../pages/fnb/separationOrder/paymentOrder/paymentOrder";
import PaymentSeparationSuccessPage from "../pages/fnb/separationOrder/paymentSuccess/PaymentSuccessPage";
import CustomerPage from "../pages/settings/customer/CustomerPage";

import PaymentUpdateProductPage from "../pages/fnb/paymentPos/PaymentUpdateProductPage";
import StartPage from "../pages/qrcode/start/StartPage";
import SettingPage from "../pages/qrcode/setting/SettingPage";
import SettingQrcodePage from "../pages/qrcode/settingQrcode/SettingQrcodePage";
import ProcessingRequestsPage from "../pages/qrcode/processingRequests/ProcessingRequestsPage";
interface IRouter {
    code: string;
    children: any;
}
const CheckRouter = ({ children, code }: IRouter) => {
    if (!checkPermission(code)) {
        return <NoAccess />;
    }
    return children;
};

const Redirect = () => {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path={`/dang-nhap`} element={<Login />} />
            <Route
                path={`/`}
                element={
                    <CheckRouter code={permissionCode.dashboard}>
                        <DashBoard />
                    </CheckRouter>
                }
            />
            <Route path={`/hang-hoa/`}>
                <Route
                    path={`san-pham`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <ProductPage />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/them-moi`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <CreateProduct />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/:id/thong-tin-chung`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <EditProductGeneral />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/:id/gia`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <EditProductVariant />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/:id/tao-ma-barcode`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <EditProductBarcode />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/:id/tao-ma-qrcode`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <EditProductQrcode />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`san-pham/:id/hinh-anh`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <EditProductImage />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`thuong-hieu`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.brands}>
                                    <Brand />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`nhom-san-pham`}
                    element={<PrivateRoute element={<GroupProduct />} />}
                />
                <Route
                    path={`danh-muc`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.categories}>
                                    <CategoryProduct />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`ma-giam-gia`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.sales}>
                                    <Coupon />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`the-san-pham`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.tags}>
                                    <TagProduct />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`nhan`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.features}>
                                    <Label />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`ton-kho`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.products}>
                                    <Inventory />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`thuoc-tinh`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.attributes}>
                                    <Attribute />
                                </CheckRouter>
                            }
                        />
                    }
                />
            </Route>
            <Route
                path={`thong-tin-tai-khoan`}
                element={<PrivateRoute element={<Info />} />}
            />
            <Route
                path={`don-hang`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.order}>
                                <Order />
                            </CheckRouter>
                        }
                    />
                }
            />
            <Route
                path={`don-hang/:id`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.order}>
                                <EditOrder />
                            </CheckRouter>
                        }
                    />
                }
            />
            <Route
                path={`in-hoa-don/:id`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.order}>
                                <PrintOrder />
                            </CheckRouter>
                        }
                    />
                }
            />

            <Route
                path={`/cai-dat/chi-nhanh-cua-hang`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.locations}>
                                <StoreBranchPage />
                            </CheckRouter>
                        }
                    />
                }
            />
            <Route
                path={`/cai-dat/chi-nhanh-cua-hang/them-moi`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.locations}>
                                <StoreBranchModal />
                            </CheckRouter>
                        }
                    />
                }
            />
            <Route
                path={`/cai-dat/chi-nhanh-cua-hang/:id/sua`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.locations}>
                                <StoreBranchModal />
                            </CheckRouter>
                        }
                    />
                }
            />

            <Route path={`/pos/`}>
                <Route
                    path={`may-pos`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.machine_pos}>
                                    <Pos />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`tang-phong`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.machine_pos}>
                                    <Room />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`tang-phong/:floorId`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.machine_pos}>
                                    <Room />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`ban-an`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.machine_pos}>
                                    <DiningTablesPage />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`chi-tiet-don-hang/:id`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.pos}>
                                    <DetailOrderPosPage />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`:id/chi-tiet-don-hang/:pos_session_id/:idViewData`}
                    element={
                        <PrivateRoute
                            element={
                                <CheckRouter code={permissionCode.pos}>
                                    <ViewDetailOrderPosPage />
                                </CheckRouter>
                            }
                        />
                    }
                />
                <Route
                    path={`phien-may/:id`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <DetailMachinePosPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/danh-sach-don-hang-phien-may/:sessionId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <OrdersSessionMachinePage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/tang-phong`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <SpacesFloorRoomTable />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/tang-phong/:floorId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <SpacesFloorRoomTable />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/tang-phong/:floorId/:roomId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <SpacesFloorRoomTable />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/san-pham/:floorId/:roomId/:diningTableId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <ProductInPosPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/tach-don/:floorId/:roomId/:diningTableId/:diningTableSession`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <SeparationPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/tach-don/thanh-cong/:floorId/:roomId/:diningTableId/:transactionPosId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentSeparationSuccessPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/thanh-toan/tach-don/:floorId/:roomId/:diningTableId/:diningTableSession`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentSeparationPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/cong-no`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <CustomerDebtPage />
                        </CheckRouter>
                    }
                ></Route>

                <Route
                    path={`:posId/chi-tiet-cong-no/:customerId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <CustomerDebtDetailPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/thanh-toan-cong-no`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentDebtPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`thanh-toan-cong-no-thanh-cong/:transactionPosId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentDebtSuccessPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/thanh-toan/:diningTableId/:diningTableSession`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentPosPage />
                        </CheckRouter>
                    }
                ></Route>
                  <Route
                    path={`:posId/thanh-toan-san-pham/:diningTableId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentUpdateProductPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/thanh-cong/:transactionPosId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <PaymentSuccessPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`phien-may/:id`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <DetailMachinePosPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/hoan-tien/:floorId/:roomId/:diningTableId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <RefundOrderPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/hoan-tien/:orderId/:floorId/:roomId/:diningTableId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <RefundOrderDetailsPage />
                        </CheckRouter>
                    }
                ></Route>
                <Route
                    path={`:posId/thanh-toan-hoan-tien/:floorId/:roomId/:diningTableId`}
                    element={
                        <CheckRouter code={permissionCode.pos}>
                            <RefundPaymentPage />
                        </CheckRouter>
                    }
                ></Route>
            </Route>

            <Route
                path={`danh-sach-pos`}
                element={
                    <PrivateRoute
                        element={
                            <CheckRouter code={permissionCode.pos}>
                                <SalePointPage />
                            </CheckRouter>
                        }
                    />
                }
            />

            <Route path={`/cai-dat/`}>
                <Route
                    path={`quan-tri-vien`}
                    element={
                        <CheckRouter code={permissionCode.admins}>
                            <AdminPage />
                        </CheckRouter>
                    }
                />
                <Route
                    path={`khach-hang`}
                    element={
                        <CheckRouter code={permissionCode.admins}>
                            <CustomerPage/>
                        </CheckRouter>
                    }
                />
                <Route
                    path={`quyen`}
                    element={
                        <CheckRouter code={permissionCode.permission}>
                            <PermissionsPage />
                        </CheckRouter>
                    }
                />
                <Route
                    path={`quyen/:id`}
                    element={
                        <CheckRouter code={permissionCode.permission}>
                            <PermissionsEditPage />
                        </CheckRouter>
                    }
                />
                <Route
                    path={`thanh-toan`}
                    element={
                        <CheckRouter code={permissionCode.payment}>
                            <PaymentsPage />
                        </CheckRouter>
                    }
                />
                <Route
                    path={`tep-tin`}
                    element={
                        <CheckRouter code={permissionCode.files}>
                            <FilesPage />
                        </CheckRouter>
                    }
                />
                <Route
                    path={`cau-hinh-mail`}
                    element={
                        <CheckRouter code={permissionCode.files}>
                            <ConfigMailPage />
                        </CheckRouter>
                    }
                />
                <Route path={`tich-hop-crm`} element={<CrmIntegrationPage />} />
            </Route>
            <Route path={`/qrcode/`}>
                <Route
                    path={`khoi-tao`}
                    element={<StartPage />}
                />
                <Route
                    path={`cau-hinh`}
                    element={<SettingPage />}
                />
                <Route
                    path={`thiet-lap-ma-qr`}
                    element={<SettingQrcodePage />}
                />
                <Route
                    path={`xu-ly-yeu-cau`}
                    element={<ProcessingRequestsPage />}
                />
            </Route>
        </Routes>
    );
};

export default Redirect;
