import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {processingRequestsStore} from "../ProcessingRequestsStore";
import {Modal} from "antd";
import SelectAnt from "../../../../shared/components/form/SelectAnt";
import {useNavigate} from "react-router-dom";

const ModalSelectMachinePos = () => {
    const navigate = useNavigate();
    return (
        <>
            <Modal title="Thanh toán" open={processingRequestsStore.isModalOpenSelectMachinePos}
                   onOk={processingRequestsStore.handleOkSelectMachinePos}
                   onCancel={processingRequestsStore.handleCancelSelectMachinePos}
                   footer={null}
                   afterClose={() => {
                       processingRequestsStore.pos_id = undefined
                   }}
            >
                <div className="form-group form-group-select">
                    <SelectAnt
                        placeholder={`-- Chọn máy Pos --`}
                        value={processingRequestsStore.pos_id}
                        isError={processingRequestsStore.errors.pos_id}
                        onChange={(e: any) => (processingRequestsStore.pos_id = e)}
                        options={processingRequestsStore.listPos.length > 0 ? processingRequestsStore.listPos.filter((item: any) => item.is_working === 1) : []}
                        isShowClear
                    />
                </div>

                <div className={`btn-cancel`} onClick={() => {
                    let dataPay = processingRequestsStore.listRequest.find((item:any) => item.id === processingRequestsStore.paramsModal.id)
                    let statusPay = dataPay.status === 'PROCESSING' ? 'COMPLETED' : 'PROCESSING'
                    var textPay = dataPay.status === 'PROCESSING' ? 'Hoàn thành' : 'Xác nhận'
                    processingRequestsStore.fetchApprovePayRequest(processingRequestsStore.paramsModal.id,statusPay, (route) => navigate(route))
                }}>
                    <button>
                        Xác nhận
                    </button>
                </div>
                <p className={'note'}>Lưu ý: Chỉ chọn được máy pos đang được mở</p>
            </Modal>
        </>
    )
}
export default observer(ModalSelectMachinePos)