import {makeAutoObservable} from "mobx";
import {diningTablesService} from "../../settings/pos/diningTables/DiningTablesService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {settingQrcodeService} from "./SettingQrcodeService";


class SettingQrcodeStore{
    isLoadingInstallQr: boolean = false
    isSave: boolean = false
    listBanner: string[] = []
    filterFloorRooms: any[] = []
    dataDiningTables: any[] = []
    dataFloorRoom: any[] = []
    filterDiningTables: any[] = []
    dataQr: any
    params: {
        location_id: number | null,
        floor_room_id: number | null,
        table_id: number | null,
        redirect_path: string,
        type_code: string,
    } = {
        location_id:null,
        floor_room_id:null,
        table_id:null,
        redirect_path: '',
        type_code: 'type_code',
    }
    errors: any = {
        location_id: '',
        floor_room_id: '',
        table_id: '',
    }
    clearError() {
        this.errors = {
            location_id: "",
            floor_room_id: "",
            table_id: "",
        };
    }
    checkValidate = () => {
        this.clearError();
        let {location_id,floor_room_id,table_id} = this.params;
        if(!location_id){ //trim() sẽ loại bỏ tất cả các khoảng trắng ở đầu và cuối chuỗi,kiểm tra nếu chuỗi chỉ chứa toàn dấu cách
            this.errors.location_id = "Vui lòng chọn địa điểm!";
            return true
        }
        if(!floor_room_id){ //trim() sẽ loại bỏ tất cả các khoảng trắng ở đầu và cuối chuỗi,kiểm tra nếu chuỗi chỉ chứa toàn dấu cách
            this.errors.floor_room_id = "Vui lòng chọn tầng/phòng!";
            return true
        }
        if(!table_id){ //trim() sẽ loại bỏ tất cả các khoảng trắng ở đầu và cuối chuỗi,kiểm tra nếu chuỗi chỉ chứa toàn dấu cách
            this.errors.table_id = "Vui lòng chọn tầng/phòng!";
            return true
        }
        return false
    }

    async fetchInstallQrOrderCode() {
        if(this.checkValidate()){
            toastUtils.warning("Vui lòng nhập đầy đủ thông tin", "");
            return false;
        }
        if(this.isSave === false){
            this.isSave = true;
            this.isLoadingInstallQr = true
            const params = {
                location_id: this.params.location_id,
                floor_room_id: this.params.floor_room_id,
                table_id: this.params.table_id,
                redirect_path: localStorage.getItem("domainName")+'login',
                type_code: 'TABLE',
            }
            const result = await settingQrcodeService.fetchInstallQrOrderCode(params);
            this.isLoadingInstallQr = false
            this.isSave = false;
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Tạo mã qr thành công", "");
                this.dataQr = result.body
            }else{
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
    async fetchAllDiningTablesList(idLocation:any) {
        const result = await settingQrcodeService.fetchAllDiningTablesList(idLocation);
        if (result.status === HttpStatusCode.Ok) {
            this.dataDiningTables = result.body;
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
 async fetchListFloorRoom() {
        const result = await settingQrcodeService.fetchListFloorRoom();
        if (result.status === HttpStatusCode.Ok) {
            this.dataFloorRoom = result.body;
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }


}

export const settingQrcodeStore = new SettingQrcodeStore()