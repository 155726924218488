import React, {useEffect} from "react";
import "./RefundOrderControl.scss";
import {
    IdataFormAddCart,
    productInPosCartStore,
} from "../../../productInPos/ProductInPosCartStore";
import {utils} from "../../../../../common/utils/Utils";
import {dataNumber} from "../../../productInPos/components/productInPosControl/ProductInPosControl";
import {observer} from "mobx-react";
import {refundOrderStore} from "../../RefundOrderStore";
import {TransactionType} from "../../../../../common/constants/Constants";
import {toastUtils} from "../../../../../common/utils/Toastutils";
import {useNavigate, useParams} from "react-router-dom";

const RefundOrderControl = () => {
    const navigate = useNavigate();
    const {posId, orderId, floorId, roomId, diningTableId} = useParams();
    useEffect(() => {
        refundOrderStore.activeVariantProduct = refundOrderStore.dataListProRefund[0]?.idData;
    }, [refundOrderStore.dataListProRefund]);
    return (
        <div className="RefundOrderControl">
            {refundOrderStore.getTransactionType !== TransactionType.DEBT_POS ? (
                <>
                    <div>
                        <div className="ProductInPosControl-body">
                            {refundOrderStore.dataListProRefund.length > 0 ? (
                                refundOrderStore.dataListProRefund.map((item: IdataFormAddCart) => (
                                        <div
                                            className={`ProductInPosControl-product ${item.idData} ${refundOrderStore.activeVariantProduct} ${refundOrderStore.activeVariantProduct === item.idData ? "active" : ""}`}
                                            onClick={() => {
                                                refundOrderStore.activeVariantProduct = item.idData;
                                            }}
                                            key={item.idData}
                                        >
                                            <div className="boxProduct-infor">
                                                <h2>
                                                    {item.productName}{" "}
                                                    {item.variant.name ? `- ${item.variant.name}` : ""}
                                                </h2>
                                                <p>
                                                    {item.quantity} {item.unit} x{" "}
                                                    {utils.formatCurrency(Number(item.price))}/{" "}
                                                    {item.unit}
                                                </p>
                                                {item.discount > 0 ||
                                                String(item.discount).includes(".") ? (
                                                    <p>Chiết khấu {item.discount}% với một sản phẩm</p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="boxProduct-total">
                                                {utils.formatCurrency(
                                                    item.discount > 0
                                                        ? ((item.price as number) *
                                                            item.quantity *
                                                            (100 - item.discount)) /
                                                        100
                                                        : (item.price as number) * item.quantity
                                                )}
                                            </div>
                                        </div>
                                    )
                                )
                            ) : (
                                <div className="emptyCart">
                                    <img src="/assets/images/emptyCart.svg" alt=""/>
                                    <p>Bắt đầu thêm sản phẩm</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ProductInPosControl-footer">
                        {refundOrderStore.dataListProRefund.length > 0 && (
                            <div className="total-price ">
                                <p>(Giá đã bao gồm VAT)</p>{" "}
                                <h3>
                                    {" "}
                                    Tổng:{" "}
                                    {utils.formatCurrency(
                                        productInPosCartStore.CaculatorTotal(
                                            refundOrderStore.dataListProRefund
                                        )
                                    )}
                                </h3>
                            </div>
                        )}
                        <div className="action">
                            <div className="action-item">
                                <img src="/assets/icon/customer.svg" alt=""/>
                                <div
                                    className="text-truncate"
                                    title={
                                        refundOrderStore.nameCustomer
                                            ? refundOrderStore.nameCustomer
                                            : "Khách lẻ"
                                    }
                                    style={{maxWidth: "180px"}}
                                >
                                    {refundOrderStore.nameCustomer
                                        ? refundOrderStore.nameCustomer
                                        : "Khách lẻ"}
                                </div>
                            </div>
                            <div
                                className="action-item"
                                onClick={() => {
                                    refundOrderStore.fetchDetailsRefundOrder();
                                }}
                            >
                                <img src="/assets/icon/restore.svg" alt=""/>
                                Khôi phục sản phẩm
                            </div>
                        </div>
                        <div className="caculator">
                            <div className="caculator-number">
                                {dataNumber.map((item) => (
                                    <div
                                        className={`caculator-number__Minbox ${
                                            refundOrderStore.productControl.type === item.id
                                                ? "active"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            if (
                                                item.id === "sl" ||
                                                item.id === "ck" ||
                                                item.id === "price"
                                            ) {
                                                // Cập nhật cả type và keycap cho các nút đặc biệt
                                                refundOrderStore.ChangeValueProductInfoRefund(
                                                    item.id, // Cập nhật type
                                                    item.id, // Cập nhật keycap
                                                    refundOrderStore.activeVariantProduct
                                                );
                                            } else {
                                                // Chỉ cập nhật keycap cho các nút khác
                                                refundOrderStore.ChangeValueProductInfoRefund(
                                                    refundOrderStore.productControl.type, // Giữ nguyên type
                                                    item.id, // Cập nhật keycap
                                                    refundOrderStore.activeVariantProduct
                                                );
                                            }
                                        }}
                                        key={item.id}
                                    >
                                        {item.content}
                                        {item.icon && <img src={item.icon} alt="img"/>}
                                    </div>
                                ))}
                            </div>
                            <div
                                onClick={() => {
                                    refundOrderStore.dataListProRefund.length > 0
                                        ? navigate(`/pos/${posId}/thanh-toan-hoan-tien/${floorId}/${roomId}/${diningTableId}`)
                                        : toastUtils.warning("Bạn chưa chọn sản phẩm nào");
                                }}
                                className="caculator-payment"
                            >
                                <img src="/assets/icon/arrowPayment.svg" alt="arr"/>
                                Hoàn Tiền
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="order-debt">Đơn hàng nợ không thể hoàn hàng</div>
            )}
        </div>
    );
};

export default observer(RefundOrderControl);
