import React, { useEffect, useRef, useState } from "react";
import { Divider, Modal, Skeleton } from "antd";
import "./ModalDetailProduct.scss";
import { utils } from "../../../../../../common/utils/Utils";
import { observer } from "mobx-react";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "../../../../../../shared/components/loading/Loading";
import { useParams } from "react-router-dom";
import StorageService from "../../../../../../common/service/StorageService";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import Slider from "react-slick";
import { toJS } from "mobx";
import { dataStatusTable } from "../../../../../../common/constants/Constants";
import { productInPostStore } from "../../../ProductInPostStore";


const ModalDetailProduct = () => {
  const [activeVariants, setActiveVariants] = useState<{
    [key: number]: number;
  }>({});

  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);

  let sliderRef1 = useRef<Slider | null>(null);
  let sliderRef2 = useRef<Slider | null>(null);

  const { posId } = useParams();
  const { diningTableId } = useParams();
  productInPosCartStore.diningTablesId = diningTableId;
  productInPosCartStore.postId = posId;
  const roomId = StorageService.getLocalStore("roomID");

  useEffect(() => {
    if (
      productInPosCartStore.dataImageFull.length > 0 &&
      sliderRef1.current &&
      sliderRef2.current
    ) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, [productInPosCartStore.dataImageFull]);

  const handleSliderSync = (index: number) => {
    if (sliderRef2.current) {
      sliderRef2.current.slickGoTo(index);
    }
  };

  // useEffect(() => {
  //   if (productInPosCartStore.dataDetailProduct.id) {
  //      productInPosCartStore.getListVariant();
  //     productInPosCartStore.getProductByType("image");
  //   }
  // }, [productInPosCartStore.dataDetailProduct.id]);

  useEffect(() => {
    if (productInPosCartStore.dataDetailProduct.id) {
      const fetchData = async () => {
        // await productInPosCartStore.getListVariant();
        // await productInPosCartStore.getProductByType("image");
        // await productInPosCartStore.getProductByType("variant"); // mới đầu vào gọi để lấy full biến thể nó đang có

        await Promise.all([
          productInPosCartStore.getListVariant(),
          productInPosCartStore.getProductByType("image"),
          productInPosCartStore.getProductByType("variant"),
        ]);

        const initialActiveVariants: { [key: number]: number } = {};
        const initialActiveVariantsName: { [key: number]: string } = {};

        if (productInPosCartStore.dataVariant) {
          // ép arr thành number
          const ArrNumberAttributeIds = productInPosCartStore?.dataDetailProduct?.attribute_ids.map((id: any) => Number(id));

          productInPosCartStore.dataVariant.forEach((variant: any) => {
            const foundVariant = variant.childs.find((child: any) => {
              return ArrNumberAttributeIds?.includes(
                child.id
              );
            });

            if (foundVariant) {
              initialActiveVariants[variant.parent.id] = foundVariant.id;
              initialActiveVariantsName[variant.parent.id] = foundVariant.name;
            }
          });

          setActiveVariants(initialActiveVariants);
        }
      };

      fetchData();
    }
  }, [productInPosCartStore.dataDetailProduct]);

  useEffect(() => {
    const detailVariantId = selectedVariant?.id;

    if (
      productInPosCartStore.dataImageFull.length > 0 &&
      productInPosCartStore.arrVariantChoose.length > 0 &&
      detailVariantId
    ) {
      // Lọc những ảnh có id hợp lệ và tìm ảnh phù hợp
      const validImages = productInPosCartStore.dataImageFull.filter(
        (item: any) => item.id !== null
      );
      const selectedImage = validImages.find(
        (item: any) => item.id === detailVariantId
      );

      if (selectedImage) {
        const imageIndex =
          productInPosCartStore.dataImageFull.indexOf(selectedImage);

        if (sliderRef1.current && sliderRef2.current && imageIndex !== -1) {
          sliderRef1?.current?.slickGoTo(imageIndex);
          sliderRef2?.current?.slickGoTo(imageIndex);
        }
      } else {
        console.warn("Không tìm thấy ảnh khớp với variant được chọn.");
      }
    }
  }, [
    productInPosCartStore.arrVariantChoose,
    productInPosCartStore.dataImageFull,
  ]);

  useEffect(() => {
    const valuesAsStrings = Object.values(activeVariants).map((value) =>
      value.toString()
    );
    productInPosCartStore.arrVariantChoose = valuesAsStrings;
  }, [activeVariants]);

  const handleDecrease = () => {
    if (productInPosCartStore.dataFormAddCart.quantity > 1) {
      productInPosCartStore.dataFormAddCart.quantity -= 1;
    }
  };

  const handleIncrease = () => {
    productInPosCartStore.dataFormAddCart.quantity += 1;
  };

  const handleChange = (e: any) => {
    const value = Number(e.target.value);
    productInPosCartStore.dataFormAddCart.quantity = value; // Cập nhật trực tiếp trong store
  };

  const handleChooseVariant = (parentId: number, childId: number) => {
    setActiveVariants((prevState) => ({
      ...prevState,
      [parentId]: childId, // Cập nhật trạng thái active cho parent tương ứng
    }));
  };

  // Lưu lại giá trị mối lần vào bàn khác
  useEffect(() => {
    const storedCart = StorageService.getObjectStore(`cartPos${posId}-${diningTableId}`);
    if (storedCart) {
      // productInPosCartStore.dataListProInCart = [...storedCart];
      productInPosCartStore.dataListProInCart = {...storedCart};
      //Sẽ mặc định active item cart đầu tiên khi re-render
      productInPosCartStore.activeVariantProduct = storedCart?.items[0]?.idData;
      productInPosCartStore.row_id = storedCart?.items[0]?.row_id?.toString();
    }
  }, [posId,diningTableId]);

  useEffect(() => {
    // khi là biến thể đơn giản set về {} để show lên giá
    if (productInPosCartStore.isSimplePro) {
      setActiveVariants({});
    }
  }, [productInPosCartStore.isSimplePro]);

  const selectedAttributes = Object.values(activeVariants);
  const selectedVariant = productInPosCartStore.dataFullVariant.find(
    (variant) =>
      JSON.stringify(variant.attribute_ids.slice().sort()) ===
      JSON.stringify(selectedAttributes.slice().sort()) // chuyển về so sánh kiểu chuỗi
  );

  // useEffect(() => {
  //   if (productInPosCartStore.dataFullVariant && selectedVariant) {
  //     // Thực hiện các hành động với selectedVariant
  //   } else if (productInPosCartStore.isOpenModalDetailProduct) {
  //     toastUtils.warning("Sản phẩm không tồn tại !");
  //   }
  // }, [selectedVariant, productInPosCartStore.dataFullVariant]);

  return (
    <Modal
      title="Thông tin sản phẩm"
      footer={null}
      wrapClassName="ModalDetailProduct"
      open={productInPosCartStore.isOpenModalDetailProduct}
      onCancel={() => {
        productInPosCartStore.isOpenModalDetailProduct = false;
        // setActiveVariants({})
        productInPosCartStore.arrVariantChoose = [];
        productInPosCartStore.dataFormAddCart.quantity = 1;
      }}
    >
      <>
        {productInPosCartStore.isLoading ? (
          <div className="loadingPreviewProduct">
            {/* <Loading></Loading> */}
            <div className="boxImage">
              <div className="mainImage">
                <Skeleton.Image active={true} className="customskeleton-mainImg"/>
              </div>
              <div className="secondImage">
                <div className="img img-1">
                  <Skeleton.Image active={true} className="customskeleton-secondImg"/>
                </div>
                <div className="img img-2">
                  <Skeleton.Image active={true} className="customskeleton-secondImg"/>
                </div>
                <div className="img img-3">
                  <Skeleton.Image active={true} className="customskeleton-secondImg"/>
                </div>
                <div className="img img-4">
                  <Skeleton.Image active={true} className="customskeleton-secondImg"/>
                </div>
              </div>
            </div>
            <div className="productInfor">
              <Skeleton.Input active={true} block={true} className="titleHeader" />

              <div className="priceWrapper">
                <Skeleton.Input active={true} block={true} className="priceTop" />
                <Skeleton.Input active={true} block={true} className="priceBottom" />
              </div>

              <div className="quantityWrapper">
                <div className="minus">
                  <Skeleton.Button active={true} block={true}/>
                </div>
                <div className="quantity">
                  <Skeleton.Button active={true} block={true}/>
                </div>
                <div className="plus">
                  <Skeleton.Button active={true} block={true}/>
                </div>
              </div>

              <div className="variantWrapper">
                <div className="titleVariant">
                  <Skeleton.Input active={true} block={true} className="title" />
                </div>
                <div className="bodyVariant">
                  <Skeleton.Button active={true} block={true} className="content"/>
                  <Skeleton.Button active={true} block={true} className="content"/>
                  <Skeleton.Button active={true} block={true} className="content"/>
                </div>
              </div>

              <div className="variantWrapper">
                <div className="titleVariant">
                  <Skeleton.Input active={true} block={true} className="title" />
                </div>
                <div className="bodyVariant">
                  <Skeleton.Button active={true} block={true} className="content"/>
                  <Skeleton.Button active={true} block={true} className="content"/>
                  <Skeleton.Button active={true} block={true} className="content"/>
                </div>
              </div>
              
              <div className="btnAddCart">
                <Skeleton.Input active={true} block={true} className="addCart" />
              </div>
            </div>
          </div>                                  
        ) : (
          <div className="ModalDetailProduct-container">
            <div className="boxImage">
              <div>
                {productInPosCartStore.dataImageFull.length > 0 && (
                  <Slider
                    arrows={false}
                    asNavFor={(nav2 as any) || undefined}
                    ref={(slider) => (sliderRef1.current = slider)}
                    afterChange={handleSliderSync}
                  >
                    {productInPosCartStore.dataImageFull.map(
                      (item: any, index: number) => (
                        <div key={index} className="big-Image">
                          <img src={item?.thumbnail || "https://xweb.asia/uploads/empty/no_image.webp"} alt="img" />
                        </div>
                      )
                    )}
                  </Slider>
                )}
              </div>
              <div className="boxImage-thumbnail">
                {productInPosCartStore.dataImageFull.length > 0 && (
                  <Slider
                    asNavFor={(nav1 as any) || undefined}
                    ref={(slider) => (sliderRef2.current = slider)}
                    slidesToShow={productInPosCartStore.dataImageFull.length > 4 ? 4 : productInPosCartStore.dataImageFull.length}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    infinite={false}
                    beforeChange={(current, next) => {
                      sliderRef1.current?.slickGoTo(next);
                    }}
                    className={`${productInPosCartStore.dataImageFull.length <= 4 ? "imgless4" : ""}`}
                  >
                    {productInPosCartStore.dataImageFull.map(
                      (item: any, index: number) => (
                        <div key={index} className="thumnail-Image">
                          <img src={item?.thumbnail || "https://xweb.asia/uploads/empty/no_image.webp"} alt="img" />
                        </div>
                      )
                    )}
                  </Slider>
                )}
              </div>
            </div>
            <div className="productInfor">
              <h4
                style={{ maxWidth: "428px" }}
                title={productInPosCartStore.dataDetailProduct.title}
                className="multiline-ellipsis"
              >
                {productInPosCartStore.dataDetailProduct.title}
              </h4>

              {selectedVariant?.price_compare && selectedVariant?.price ? (
                <div className="productInfor-price">
                  <span>
                    {utils.formatCurrency(selectedVariant?.price_compare)}
                  </span>
                  <h1>{utils.formatCurrency(selectedVariant?.price)}</h1>
                </div>
              ) : selectedVariant?.price_compare === 0 &&
                selectedVariant?.price === 0 ? (
                <div className="productInfor-price">
                  <span>{utils.formatCurrency(0)}</span>
                  <h1>{utils.formatCurrency(0)}</h1>
                </div>
              ) : (
                ""
              )}

              <div className="productInfor-quantity">
                <span onClick={handleDecrease}>
                  <i className="fas fa-minus"></i>
                </span>
                <input
                  type="number"
                  min={1}
                  value={productInPosCartStore.dataFormAddCart.quantity || 1}
                  onChange={handleChange}
                />
                <span onClick={handleIncrease}>
                  <i className="fas fa-plus"></i>
                </span>
              </div>
              {productInPosCartStore.dataDetailProduct.is_variation
                ? productInPosCartStore.dataVariant.length > 0 &&
                  productInPosCartStore.dataVariant.map((item: any) => (
                    <div key={item?.parent?.id}>
                      <div className="productInfor-variant">
                        <div className="productInfor-variant__title">
                          <h4>{item?.parent?.name}</h4>
                        </div>
                        <div className="productInfor-variant__body">
                          {item.childs.length > 0 &&
                            item.childs.map((child: any) => (
                              <div
                                onClick={() => {
                                  handleChooseVariant(item.parent.id, child.id);
                                }}
                                className={`productInfor-variant__content ${
                                  activeVariants[item.parent.id] === child.id
                                    ? "active"
                                    : ""
                                }`}
                                key={child.id}
                              >
                                {child.name}
                              </div>
                            ))}
                        </div>
                      </div>
                      <Divider
                        style={{
                          backgroundColor: "#ECF2F7",
                          height: "1px",
                          width: "100%",
                          margin: 0,
                        }}
                      ></Divider>
                    </div>
                  ))
                : ""}

              <div
                className={`productInfor-addCart ${
                  !selectedVariant ? "disableAddtoCart" : ""
                }`}
                onClick={() => {
                  if (productInPosCartStore.isLoadingBtn) {
                    return;
                  }

                  const newCartItem = {
                    ...productInPosCartStore.dataFormAddCart,
                    variant: {
                      ...productInPosCartStore.dataFormAddCart.variant,
                      id: selectedVariant.id || null,
                      name: selectedVariant.attribute_compare,
                    },
                    product_id: selectedVariant.product_id,
                    discount: selectedVariant.discount || 0,
                    productName: productInPosCartStore.dataDetailProduct.title,
                    price: selectedVariant.price,
                    unit: selectedVariant.unit,
                    sku: selectedVariant.sku,
                    row_id: "",
                    row_id_toAddDel: "",
                    idData: null,
                    edited: false
                  };

                  // Lưu state để truyền dữ liệu vào add to cart api
                  productInPosCartStore.dataAddToCart = newCartItem
                  productInPosCartStore.addToCartFNB();

                  // const currentCart =
                  //   StorageService.getArrayFromLS(`cartPos${postId}/cartTable${diningTableId}`) || { dining_table_status: "", items: [] };
                  // const currentCart =
                  //   StorageService.getObjectStore(`cartPos${postId}/cartRoom${roomId}/cartTable${diningTableId}`) || { dining_table_status: "", items: [] };

                  // Nếu như roomId = (bàn không thuộc phòng cha nào) gán là "" 
                  const currentCart =
                    StorageService.getObjectStore(`cartPos${posId}-${diningTableId}`) || {
                      dining_table_status: `${dataStatusTable.cart}`,
                      dining_table_session: "",
                      merge_dining_tables_id: "",
                      items: []
                  };

                  const filterProduct = currentCart.items?.find(
                    (item: any) =>
                      item.product_id === newCartItem.product_id &&
                      item.variant.id === newCartItem.variant.id && item.tmp_order_status.toString() === newCartItem.tmp_order_status.toString()
                  );
                  if (filterProduct) {
                    filterProduct.quantity =
                      Number(filterProduct.quantity) + newCartItem.quantity;
                    productInPosCartStore.flagActiveVariant = true; // mục đích chỉ để active highlight productVariant trong cart
                    productInPosCartStore.activeVariantProduct =
                      selectedVariant.id;
                  } else {
                    productInPosCartStore.flagActiveVariant = false;
                    currentCart.dining_table_status = `${dataStatusTable.cart}`
                    currentCart.items?.unshift(newCartItem);
                  }

                  productInPosCartStore.dataListProInCart = currentCart;
                  // Nếu như roomId = (bàn không thuộc phòng cha nào) gán là "" 
                  StorageService.setObjectStore(`cartPos${posId}-${diningTableId}`, currentCart);
                  StorageService.setObjectStore(`cartPosOrigin${posId}-${diningTableId}`, currentCart);
                  productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name && StorageService.setObjectStore(
                    `tableMergePrimary-${productInPostStore.dataInfoTable?.dining_table_session?.merge_dining_tables_name}`,
                    currentCart,
                  );
                  // toastUtils.success("Thêm vào giỏ hàng thành công", "");
                }}
              >
                {productInPosCartStore.isAddToCart ? (
                  <p>Vui lòng chờ...</p>
                ) : (
                  <>
                    <i className="fas fa-shopping-cart"></i>
                    <p>Thêm vào giỏ hàng</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default observer(ModalDetailProduct);
