import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {processingRequestsStore} from "../ProcessingRequestsStore";
import SelectAnt from "../../../../shared/components/form/SelectAnt";
import {Modal} from "antd";
import {useNavigate} from "react-router-dom";
const  ModalOrder = () => {
    const navigate = useNavigate();
    return (
        <Modal title="Đơn hàng" open={processingRequestsStore.isModalOpenOrder}
               onOk={processingRequestsStore.handleOkOrder}
               onCancel={processingRequestsStore.handleCancelOrder}
               footer={null}
               afterClose={() => {
                   processingRequestsStore.pos_id = undefined
               }}
        >
            <div className="form-group form-group-select">
                <SelectAnt
                    placeholder={`-- Chọn máy Pos --`}
                    value={processingRequestsStore.pos_id}
                    isError={processingRequestsStore.errors.pos_id}
                    onChange={(e: any) => (processingRequestsStore.pos_id = e)}
                    options={processingRequestsStore.listPos.length > 0 ? processingRequestsStore.listPos.filter((item: any) => item.is_working === 1) : []}
                    isShowClear
                />
            </div>

            <div className={`btn-cancel`} onClick={() => {
                processingRequestsStore.fetchApproveOrderRequest(processingRequestsStore.paramsModal.id, (route) => navigate(route))
            }}>
                <button>
                    Xác nhận
                </button>
            </div>
            <p className={'note'}>Lưu ý: Chỉ chọn được máy pos đang được mở</p>
        </Modal>
    )
}
export default  observer(ModalOrder)