import React, {useEffect, useRef} from "react";
import { observer } from "mobx-react";
import './styles/index.css'
import {dashboardStore} from "../../dashboard/DashboardStore";
import SelectAnt from "../../../shared/components/form/SelectAnt";
import {settingQrcodeStore} from "./SettingQrcodeStore";
import {useReactToPrint} from "react-to-print";
import {toast} from "react-toastify";
import warning = toast.warning;
import {toastUtils} from "../../../common/utils/Toastutils";
const SettingQrcodePage = () => {
    useEffect(() => {
        dashboardStore.fetchDataStore().then();
        settingQrcodeStore.fetchListFloorRoom().then();
    }, []);

    const componentRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        const dataListFloorRoom =
            settingQrcodeStore.dataFloorRoom?.length > 0 ?
                settingQrcodeStore.dataFloorRoom?.filter((itemFilter:any) => itemFilter.location_id === settingQrcodeStore.params.location_id).map((item: any) => ({
                    id: item.id,
                    name: `${item.parent_id > 0 ? ` --`: ""} ${item?.name}`,
                })) : [];
        settingQrcodeStore.filterFloorRooms = dataListFloorRoom
        if(settingQrcodeStore.params.location_id){
            settingQrcodeStore.fetchAllDiningTablesList(settingQrcodeStore.params.location_id).then();
        }
        settingQrcodeStore.params.floor_room_id = null
        settingQrcodeStore.params.table_id = null
        // settingQrcodeStore.dataQr = {}

    }, [settingQrcodeStore.params.location_id]);

    useEffect(() => {
        const dataFilterDinningTable =
            settingQrcodeStore.dataDiningTables?.length > 0 ?
                settingQrcodeStore.dataDiningTables?.filter((itemFilter:any) => itemFilter.floor_room_id === settingQrcodeStore.params.floor_room_id).map((item: any) => ({
                    id: item.id,
                    name: item.name,
                })) : [];
        settingQrcodeStore.filterDiningTables = dataFilterDinningTable
        settingQrcodeStore.params.table_id = null
        // settingQrcodeStore.dataQr =
    }, [settingQrcodeStore.params.floor_room_id]);

    return (
        <div className={`container`}>
            <div className={`main-setting-qrcode-page`}>
                <p className={`title`}>Thiết lập mã QR</p>
                <div className={`content-main-setting-page`}>
                    <div className={`left-content-main-setting-page`}>
                        <p className={`title-item`}>
                            Tạo mã QR
                        </p>
                        <div className={`list-form`}>
                            <div className="item-group-2 item-group-select-2">
                                <label>
                                    Địa điểm <span>*</span>
                                </label>
                                <SelectAnt
                                    placeholder={`-- Chọn địa điểm --`}
                                    value={settingQrcodeStore.params.location_id}
                                    isError={settingQrcodeStore.errors.location_id}
                                    onChange={(e: any) => (settingQrcodeStore.params.location_id = e)}
                                    options={dashboardStore.dataListStore}
                                    isShowClear
                                    // isCallback
                                    // callback={()=>{console.log("Đến cuối rồi !")}}
                                />
                            </div>
                            <div className="item-group-2 item-group-select-2">
                                <label>
                                    Tầng/Phòng <span>*</span>
                                </label>
                                <SelectAnt
                                    placeholder={settingQrcodeStore.params.location_id ? `-- Chọn tầng/phòng --` : 'Vui lòng chọn địa điểm'}
                                    value={settingQrcodeStore.params.floor_room_id}
                                    isError={settingQrcodeStore.errors.floor_room_id}
                                    onChange={(e: any) => (settingQrcodeStore.params.floor_room_id = e)}
                                    options={settingQrcodeStore.params.location_id ? settingQrcodeStore.filterFloorRooms : []}
                                    isShowClear
                                    // isCallback
                                    // callback={()=>{console.log("Đến cuối rồi !")}}
                                />
                            </div>
                            <div className="item-group-2 item-group-select-2">
                                <label>
                                    Bàn ăn <span>*</span>
                                </label>
                                <SelectAnt
                                    placeholder={!settingQrcodeStore.params.location_id ? "Vui lòng chọn địa điểm" : !settingQrcodeStore.params.floor_room_id ? "Vui lòng chọn tầng/phòng" : "-- Chọn bàn ăn --"}
                                    value={settingQrcodeStore.params.table_id}
                                    isError={settingQrcodeStore.errors.table_id}
                                    onChange={(e: any) => (settingQrcodeStore.params.table_id = e)}
                                    options={settingQrcodeStore.params.floor_room_id ? settingQrcodeStore.filterDiningTables : []}
                                    isShowClear
                                    // isCallback
                                    // callback={()=>{console.log("Đến cuối rồi !")}}
                                />
                            </div>
                            <div className={`btn-qrcode`}>
                                <span onClick={() => {
                                    settingQrcodeStore.fetchInstallQrOrderCode();
                                }}>
                                    {settingQrcodeStore.isLoadingInstallQr ? 'Đang xử lý' : 'Tạo mã QR'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`right-content-main-setting-page`}>
                        <div className={`header-right-content`}>
                            <p className={`title-item`}>
                                Mẫu in
                                <span onClick={() => {
                                    if(settingQrcodeStore.dataQr){
                                        handlePrint()
                                    }else{
                                        toastUtils.warning('Vui lòng tạo mã QR','')
                                    }
                                }}>
                                    Tải QR
                                </span>
                            </p>

                        </div>
                        <div className={`image-qr-code`} ref={componentRef} style={{width:'494px',margin:'auto',border:'1px dotted #D0D6E5',padding:'12px',textAlign:'center',display:'grid',gap:'8px'}}>
                            {settingQrcodeStore.dataQr && (
                                <>
                                    <p className={`text1`}>Nhà hàng FNB</p>
                                    <p className={`text2`} style={{fontWeight:'600'}}>
                                        {dashboardStore.dataListStore.find((item:any) => item.id === settingQrcodeStore.params.location_id).name}
                                    </p>
                                    <p className={`text2`} style={{fontWeight:'600'}}>
                                        {settingQrcodeStore.dataQr.data.table?.name} - {settingQrcodeStore.dataQr.data.room ? (<>{settingQrcodeStore.dataQr.data.room?.name}/{settingQrcodeStore.dataQr.data.floor?.name}</>) : (<>{settingQrcodeStore.dataQr.data.floor?.name}</>)}
                                    </p>
                                    <div className={`image`}>
                                        <img src={settingQrcodeStore.dataQr?.qr_code} alt="qr_code"/>
                                    </div>
                                    <p className={`text3`}>Quét mã qr để gọi món/gọi nhân viên</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default observer(SettingQrcodePage)
