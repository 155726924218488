import { HttpStatusCode } from "axios";
import { makeAutoObservable } from "mobx";
import StorageService from "../../../../../../common/service/StorageService";
import { numberGuestService } from "./NumberService";
import { toastUtils } from "../../../../../../common/utils/Toastutils";
import {productInPostStore} from "../../../ProductInPostStore";

class NumberGuestStore {
    posId: string = "";
    modalOpen: boolean = false;
    diningTableId: string = "";
    guestCount: string = productInPostStore.dataInfoTable?.dining_table_session?.quantity_people_reality?.toString() || "";

    constructor() {
        makeAutoObservable(this);
    }

    appendGuestNumber(number: number) {
        this.guestCount += number.toString();
    }

    removeLastGuestNumber() {
        this.guestCount = this.guestCount.slice(0, -1);
    }

    async saveNumberGuest() {
        if(parseInt(this.guestCount) === 0 || !this.guestCount){
            toastUtils.error("Số lượng khách hàng không được để trống hoặc bằng 0!", "");
            return;
        }

        if(parseInt(this.guestCount) >= 100){
            toastUtils.error("Số lượng khách hàng phải nhỏ hơn 100!", "");
            return;
        }

        const dining_table_session = StorageService.getObjectStore(`cartPos${this.posId}-${this.diningTableId}`)?.dining_table_session

        const diningTableId = this.diningTableId;

        const saveGuest = {
            dining_table_session: dining_table_session,
            dining_table_id: diningTableId,
            quantity: parseInt(this.guestCount) || 0
        };

        const result = await numberGuestService.saveGuest(saveGuest);
        if (result.status === HttpStatusCode.Ok) {
            productInPostStore.dataInfoTable.dining_table_session.quantity_people_reality = saveGuest.quantity.toString();
            this.modalOpen = false;
            productInPostStore.isModalVisible = false;
            toastUtils.success("Cập nhật số lượng khách thành công", "");
            // localStorage.setItem("guestCount", this.guestCount);
            const posId = this.posId;
            const diningTableId = this.diningTableId;
            const roomId = productInPostStore.dataInfoTable?.room_id;
            StorageService.removeLocalStore(`cartPos${posId}/cartRoom${roomId}/cartTable${diningTableId}`);
        }
    }
}

export const numberGuestStore = new NumberGuestStore();
