import {getRequest, postRequest} from "../../../common/helpers/RequestHelper";
class SettingQrcodeService {
    apiInstallQrOrderCode = `api/v1/qr_order_code/install_qr_order_code`;
    apiListFloorRoom = `api/v1/floor_room/get_tree_floor_room`;
    apiDiningTables = `api/v1/dining_tables/get_by_location/`;
    public fetchInstallQrOrderCode(params:any) {
        return postRequest(`${this.apiInstallQrOrderCode}`,params);
    }
    public fetchListFloorRoom() {
        return getRequest(`${this.apiListFloorRoom}`);
    }
    public fetchAllDiningTablesList(idLocation:any) {
        return getRequest(`${this.apiDiningTables}${idLocation}`);
    }
}
export const settingQrcodeService = new SettingQrcodeService()