
// Viết api
import {getRequest, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class ProcessingRequestsService {
    apiGetOrderRequest = `api/v1/qr_order_code/get_order_request`;
    apiApproveRequestOrder = `api/v1/qr_order_code/approve_request_order/`;
    apiMachinePos = `api/v1/machine_pos`;
    public fetchGetOrderRequest(locationIdActive:any,floorRoomIdActive:any,isRequest:string,statusRequest:any,params:any) {
        return getRequest(`${this.apiGetOrderRequest}?location_id=${locationIdActive}&floor_room_id=${floorRoomIdActive}&page=${params.page}&per_page=${params.per_page}${isRequest !== 'ALL' ? `&type=${isRequest}` : ''}&status=${statusRequest}`);
    }
    public fetchApproveRequest(params:any,idRequest:string) {
        return putRequest(`${this.apiApproveRequestOrder}${idRequest}`,params);
    }
    public fetchListPos(params:any) {
        return getRequest(`${this.apiMachinePos}?&store_id=${params.store_id}&per_page=${params.per_page}&status=${params.status}`);
    }
}
export const processingRequestsService = new ProcessingRequestsService()