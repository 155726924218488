import React, {useEffect} from "react";
import {observer} from "mobx-react";
import LayoutSettings from "../components/layout/LayoutSettings";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import {permissionsStore} from "./PermissionsStore";
import FilterAdmin from "./components/FilterAdmin";
import AddPermissions from "./components/addPermissions/AddPermissions";
import ReactPaginate from "react-paginate";
import {createSearchParams, useNavigate} from "react-router-dom";
import {showDelete} from "../../../shared/components/showDelete";
import {categoryProductStore} from "../../products/categoryProduct/CategoryProductStore";
import StorageService from "../../../common/service/StorageService";
const  PermissionsPage = () => {
    permissionsStore.dataDetailListPermissionRole = []
    permissionsStore.dataDetailPermissionRole = {}
    const navigate = useNavigate();
    useEffect(() => {
        permissionsStore.fetchListRole().then()
        permissionsStore.fetchListPermissions().then()
    }, []);
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (permissionsStore.staticParam.search !== "")
            params.search = permissionsStore.staticParam.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        permissionsStore.staticParam.page_number = selectedPage;
        permissionsStore.fetchListRole(true);
    };

    return(
        <LayoutSettings keyActive={"quyen"}>
            <div className="AdminPage">
                <div className="AdminPage-header">
                    <h1 className="title">Quyền</h1>
                    <FilterAdmin/>
                </div>
                <div className="AdminPage-body">
                    <div className="table-container">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead className="AdminPage-body__thead">
                                <tr>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        STT
                                    </th>
                                    <th
                                        style={{
                                            width: "50%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Tên
                                    </th>
                                    <th
                                        style={{
                                            width: "30%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày tạo
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Thao tác
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {permissionsStore.isLoading ? (
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}>
                                            <Loading/>
                                        </td>
                                    </tr>
                                ) : permissionsStore.dataListRole.length <= 0 ? (
                                    <tr>
                                        <td colSpan={4}>
                                            {" "}
                                            <NoContent/>{" "}
                                        </td>
                                    </tr>
                                ) : (
                                    permissionsStore.dataListRole.length > 0 &&
                                    permissionsStore.dataListRole.map((item: any,index:number) => (
                                        <tr key={item?.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {new Date(item.created_at).toLocaleDateString(
                                                    "en-GB"
                                                )}
                                            </td>
                                            <td>
                                                <div className="actionUser d-flex gap-2">
                                                    <img
                                                        onClick={() => {
                                                            navigate(`/cai-dat/quyen/${item?.id}`)
                                                        }}
                                                        src="/assets/icon/icon-PenEdit.svg"
                                                        alt="img-default"
                                                    />
                                                    <img
                                                        onClick={() => {
                                                            showDelete.showDeleteConfirm(
                                                                "Xác nhận xoá nhóm quyền",
                                                                "Bạn chắc chắn muốn xoá nhóm quyền này? Hành động này không thể hoàn tác",
                                                                () =>
                                                                    permissionsStore.fetchDeleteRole(item?.id)
                                                            );
                                                        }}
                                                        src="/assets/icon/icon-trash.svg"
                                                        alt="img-default"
                                                        className="ms-1"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                            {permissionsStore.isLoading
                                ? ""
                                : Math.ceil(
                                permissionsStore.metadata.total_page
                            ) > 1 && (
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-left.svg"
                                            alt="right"
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src="/assets/icon/fi-rr-angle-small-right.svg"
                                            alt="right"
                                        />
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                        permissionsStore.metadata.total_page
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={
                                        permissionsStore.staticParam.page_number
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <AddPermissions/>
            </div>
        </LayoutSettings>
    )
}
export default observer(PermissionsPage)