import React, { useEffect } from "react";
import "./ProductInPosControl.scss";
import { observer } from "mobx-react";
import ModalListCustomer from "../modal/modalListCustomer/ModalListCustomer";
import {
    IdataFormAddCart,
    productInPosCartStore,
} from "../../ProductInPosCartStore";
import { utils } from "../../../../../common/utils/Utils";
import StorageService from "../../../../../common/service/StorageService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toJS } from "mobx";
import { toastUtils } from "../../../../../common/utils/Toastutils";
import { transferTableStore } from "../modal/transferTable/TransferTableStore";
import TransferTable from "../modal/transferTable/TransferTable";
import ModalOption from "../modal/modalOption/ModalOption";
import { productInPostStore } from "../../ProductInPostStore";
import { numberGuestStore } from "../modal/numberGuests/NumberStore";
import NumberGuest from "../modal/numberGuests/NumberGuest";
import ModalNoteCartItems from "../modal/modalNoteCartItems/ModalNoteCartItems";
import { dataStatusTable } from "../../../../../common/constants/Constants";
export const dataNumber = [
    { id: 1, content: "1" },
    { id: 2, content: "2" },
    { id: 3, content: "3" },
    { id: "sl", content: "SL" },
    { id: 4, content: "4" },
    { id: 5, content: "5" },
    { id: 6, content: "6" },
    { id: "ck", content: "%CK" },
    { id: 7, content: "7" },
    { id: 8, content: "8" },
    { id: 9, content: "9" },
    { id: "price", content: "Giá" },
    { id: "del1", content: "Xóa" },
    { id: 0, content: "0" },
    { id: "dots", content: "." },
    { id: "del2", content: "", icon: "/assets/icon/deletePayment.svg" },
];

const ProductInPosControl = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { posId } = useParams();
    const { diningTableId } = useParams();
    productInPosCartStore.diningTablesId = diningTableId;
    const results = StorageService.getObjectStore(
        `cartPos${posId}-${diningTableId}`
    );
    const isEdited = results?.items?.some((item: any) => item.edited)

    useEffect(() => {
        if(productInPosCartStore.dataListProInCart.length > 0 && !productInPosCartStore.flagActiveVariant) {
            productInPosCartStore.activeVariantProduct = productInPosCartStore?.dataListProInCart[0].variant.id;
        }
    }, [productInPosCartStore?.dataListProInCart]);

    useEffect(() => {
        const storedCart = StorageService.getObjectStore(
            `customerPos${posId}-${diningTableId}`
        );
        if (storedCart) {
            productInPosCartStore.dataCustomer = { ...storedCart};
        }
        
    }, [posId, diningTableId]);

    const renderBookingButton = () => {
        const { dining_table_session, cart_list } = productInPostStore.dataInfoTable || {};
        // Status = "" và dining_tb_ss = null thì hiện Đặt bàn
        if (!productInPosCartStore.dataListProInCart?.dining_table_status && !dining_table_session) {
            return (
                <div
                    className="ProductInPosControl-booking"
                    style={{ display: "flex" }}
                    onClick={() => {
                        if (!productInPosCartStore.isLoadingBooking) {
                            productInPosCartStore.startBookingTable();
                        }
                    }}
                >
                    <span>{productInPosCartStore.isLoadingBooking ? "Vui lòng đợi..." : "Đặt bàn"}</span>
                </div>
            );
        }

        // Nếu status = "" và (end_time mà có hoặc cart_list > 0) thì Đặt bàn
        if (!productInPosCartStore.dataListProInCart?.dining_table_status && (dining_table_session?.end_time_working || cart_list?.length > 0)) {
            return (
                <div
                    className="ProductInPosControl-booking"
                    style={{ display: "flex" }}
                    onClick={() => {
                        if (!productInPosCartStore.isLoadingBooking) {
                            productInPosCartStore.startBookingTable();
                        }
                    }}
                >
                    <span>{productInPosCartStore.isLoadingBooking ? "Vui lòng đợi..." : "Đặt bàn"}</span>
                </div>
            );
        }

        if ((productInPosCartStore.dataListProInCart?.dining_table_status?.toString() === dataStatusTable.init && !dining_table_session?.end_time_working) && (dining_table_session.dining_tables_status?.toString() === dataStatusTable.booked.toString() && cart_list.length === 0)) {
            return (
                <div
                    className="ProductInPosControl-booking"
                    style={{ display: "flex" }}
                    onClick={() => {
                        if (!productInPosCartStore.isLoadingBooking) {
                            productInPosCartStore.startBookingTable();
                        }
                    }}
                >
                    <span>{productInPosCartStore.isLoadingBooking ? "Vui lòng đợi..." : "Đặt bàn"}</span>
                </div>
            );
        }

        //Nếu status = 1 (Bàn đã được đặt-đã có món order) thì không hiện G.Phòng hay D.Bàn 
        if (productInPosCartStore.dataListProInCart?.dining_table_status?.toString() === dataStatusTable.order.toString() &&
        !dining_table_session?.end_time_working) {
            return ""
        }

        //Nếu như status = 2 (Bàn được đặt-chưa có món) và end_time = null thì Giải Phóng
        if (productInPosCartStore.dataListProInCart?.dining_table_status?.toString() === dataStatusTable.booked.toString() && !dining_table_session?.end_time_working) {
            return (
                <div
                    className="ProductInPosControl-booking"
                    style={{ display: "flex" }}
                    onClick={() => {
                        if (!productInPosCartStore.isLoadingBooking) {
                            productInPosCartStore.endBookingTable();
                        }
                    }}
                >
                    <span>{productInPosCartStore.isLoadingBooking ? "Vui lòng đợi..." : "Giải phóng bàn"}</span>
                </div>
            );
        }

        return "";
    }

    useEffect(()=>{
        productInPostStore.closeModal()
    },[location.pathname])

    return (
        <div
            className={`ProductInPosControl ${
                productInPostStore.isLoadingDetailTable ? "disabled" : ""
            }`}
        >
            <div>
                <div className="ProductInPosControl-body">
                    {productInPosCartStore.dataListProInCart?.items?.length >
                    0 ? (
                        productInPosCartStore.dataListProInCart?.items?.map(
                            (item: IdataFormAddCart) => (
                                <div
                                    className={`ProductInPosControl-product ${
                                        productInPosCartStore.activeVariantProduct ===
                                        item.idData
                                            ? "active"
                                            : ""
                                    }`}
                                    style={{
                                        borderLeft:
                                            item.tmp_order_status === "2"
                                                ? "4px solid#029334" : item.edited ? "4px solid#FF6B00"
                                                : "none",
                                    }}
                                    onClick={() => {
                                        // productInPosCartStore.activeVariantProduct =
                                        //     item.variant.id;
                                        // productInPosCartStore.diningTableSession = item.dining_tables_session;
                                        productInPosCartStore.activeVariantProduct =
                                            item.idData;
                                        productInPosCartStore.row_id =
                                            item.row_id;
                                        productInPosCartStore.dataNote.note =
                                            item.note;
                                    }}
                                    key={item.idData}
                                >
                                    <div className="boxProduct-infor">
                                        <h2
                                            style={{
                                                color:
                                                    item.tmp_order_status.toString() ===
                                                    dataStatusTable.booked.toString()
                                                        ? "#029334" : item.edited ? "#FF6B00"
                                                        : "#061020",
                                            }}
                                        >
                                            {item.productName}{" "}
                                            {item.variant.name
                                                ? `- ${item.variant.name}`
                                                : ""}
                                        </h2>
                                        <p
                                            style={{
                                                color:
                                                    item.tmp_order_status.toString() ===
                                                    dataStatusTable.booked.toString()
                                                        ? "#029334" : item.edited ? "#FF6B00"
                                                        : "#061020",
                                            }}
                                        >
                                            {item.quantity} {item.unit} x{" "}
                                            {utils.formatCurrency(
                                                Number(item.price)
                                            )}
                                            / {item.unit}
                                        </p>
                                        {item.discount > 0 ||
                                        String(item.discount).includes(".") ? (
                                            <p
                                                style={{
                                                    color:
                                                        item.tmp_order_status.toString() ===
                                                        dataStatusTable.booked.toString()
                                                            ? "#029334" : item.edited ? "#FF6B00"
                                                            : "#061020",
                                                }}
                                            >
                                                Chiết khấu {item.discount}% với
                                                một sản phẩm
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div
                                        className="boxProduct-total"
                                        style={{
                                            color:
                                                item.tmp_order_status.toString() ===
                                                dataStatusTable.booked.toString() 
                                                    ? "#029334": item.edited ? "#FF6B00"
                                                    : "#061020",
                                        }}
                                    >
                                        {utils.formatCurrency(
                                            item.discount > 0
                                                ? ((item.price as number) *
                                                      item.quantity *
                                                      (100 - item.discount)) /
                                                      100
                                                : (item.price as number) *
                                                      item.quantity
                                        )}
                                    </div>
                                    {!item.note ? (
                                       ""
                                    ) : (
                                        <p
                                            style={{
                                                color:
                                                    item.tmp_order_status.toString() ===
                                                    dataStatusTable.booked.toString()
                                                        ? "#029334" : item.edited ? "#FF6B00"
                                                        : "#061020",
                                            }}
                                            className="boxProduct-note"
                                        >
                                            <img
                                                src="/assets/icon/noteProduct.svg"
                                                alt="icon_note"
                                            />

                                            {item.note}
                                        </p>
                                    )}
                                </div>
                            )
                        )
                    ) : (
                        <div className="emptyCart">
                            <img src="/assets/images/emptyCart.svg" alt="" />
                            <p>Bắt đầu thêm sản phẩm</p>
                        </div>
                    )}
                </div>                           
            </div>
            {renderBookingButton()}
            <div className="ProductInPosControl-footer">
                {productInPosCartStore.dataListProInCart?.items?.length > 0 && (
                    <div className="total-price ">
                        <p>(Giá đã bao gồm VAT)</p>{" "}
                        <h3>
                            {" "}
                            Tổng:{" "}
                            {utils.formatCurrency(
                                productInPosCartStore.CaculatorTotal(
                                    productInPosCartStore.dataListProInCart
                                        .items
                                )
                            )}
                        </h3>
                    </div>
                )}
                <div className="action">
                    <div
                        className="action-item"
                        onClick={() => {
                            productInPostStore.isOpenModalListCustomer = true;
                        }}
                    >
                        <img
                            src="/assets/icon/customer.svg"
                            width={14}
                            height={14}
                            alt=""
                        />
                        <div
                            className="text-truncate"
                            style={{ maxWidth: "100px" }}
                        >
                            {productInPosCartStore.dataCustomer
                                ? productInPosCartStore.dataCustomer?.name
                                : "Khách hàng"}
                        </div>
                    </div>
                    <div
                        className="action-item"
                        onClick={() => {
                            productInPosCartStore.isOpenModalNoteCartItem =
                                true;
                        }}
                    >
                        <img
                            src="/assets/icon/note_fnb.svg"
                            width={14}
                            height={14}
                            alt="ghi chu"
                        />
                        Ghi chú món
                    </div>
                    <div
                        className="action-item"
                        onClick={() => {
                            if (results?.dining_table_session) {
                                if (results?.merge_dining_tables_id === "") {
                                    transferTableStore.isModalOpen = true;
                                } else {
                                    toastUtils.warning("Bàn gộp không thể chuyển bàn !");
                                }
                            } else {
                                toastUtils.warning("Vui lòng thêm món hoặc đặt bàn !");
                            }
                        }}
                    >
                        <img
                            src="/assets/icon/switch-table.svg"
                            width={14}
                            height={14}
                            alt="chuyen ban"
                        />
                        Chuyển bàn
                    </div>
                    {transferTableStore.isModalOpen && <TransferTable />}
                </div>
                <div className="action" style={{ marginTop: "2px" }}>
                    <div
                        className="action-item"
                        onClick={() => {
                            if (results?.dining_table_session && !productInPostStore.dataInfoTable?.dining_table_session?.end_time_working) {
                                numberGuestStore.modalOpen = true;
                            } else {
                                toastUtils.warning("Vui lòng thêm món hoặc đặt bàn !"); 
                            }
                        }}
                    >   
                        {/* Check nếu có end_time thì sẽ hiển thị khách = 0 */}
                        {!productInPostStore.dataInfoTable
                            .dining_table_session ? ("") : productInPostStore.dataInfoTable?.dining_table_session.end_time_working ? (
                            ""
                        ) : (
                            <div className="quantity-customer">
                                {
                                    productInPostStore.dataInfoTable
                                        ?.dining_table_session
                                        ?.quantity_people_reality
                                }
                            </div>
                        )}
                        <div
                            className="text-truncate"
                            style={{maxWidth: "100px"}}
                        >
                            Khách
                        </div>
                    </div>
                    {numberGuestStore.modalOpen && <NumberGuest/>}
                    <div
                        className="action-item"
                        onClick={() => productInPostStore.openModal()}
                        // onClick={productInPostStore.openModal}
                    >
                        Thêm...
                    </div>
                    {productInPostStore.isModalVisible && <ModalOption />}
                </div>
                <div className="caculator">
                    <div className="caculator-number">
                        {dataNumber.map((item) => (
                            <div
                                className={`caculator-number__Minbox ${
                                    productInPosCartStore.productControl
                                        .type === item.id
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (
                                        item.id === "sl" ||
                                        item.id === "ck" ||
                                        item.id === "price"
                                    ) {
                                        // Cập nhật cả type và keycap cho các nút đặc biệt
                                        productInPosCartStore.ChangeValueProductInfo(
                                            item.id, // Cập nhật type
                                            item.id, // Cập nhật keycap
                                            productInPosCartStore.activeVariantProduct
                                        );
                                    } else {
                                        // Chỉ cập nhật keycap cho các nút khác
                                        productInPosCartStore.ChangeValueProductInfo(
                                            productInPosCartStore.productControl
                                                .type, // Giữ nguyên type
                                            item.id, // Cập nhật keycap
                                            productInPosCartStore.activeVariantProduct
                                        );
                                    }
                                }}
                                key={item.id}
                            >
                                {item.content}
                                {item.icon && <img src={item.icon} alt="img" />}
                            </div>
                        ))}
                    </div>
                    <div className="caculator-action">
                        <div
                            onClick={() => {
                                if (
                                    productInPosCartStore.dataListProInCart
                                        ?.items.length > 0
                                ) {
                                    if (
                                        !productInPosCartStore.dataListProInCart?.items?.some(
                                            (item: any) =>
                                                item?.tmp_order_status.toString() ===
                                                "1"
                                        ) || isEdited
                                    ) {
                                        toastUtils.warning(
                                            "Vui lòng order trước",
                                            ""
                                        );    
                                        return;
                                    } else {
                                        navigate(`/pos/${posId}/thanh-toan-san-pham/${diningTableId}`)
                                    }
                                } else {
                                    toastUtils.warning(
                                        "Bạn chưa chọn món nào",
                                        ""
                                    );
                                }
                            }}
                            className="caculator-payment"
                        >
                            {productInPosCartStore.isLoadingBtn ? (
                                <span
                                    style={{
                                        padding: "3px 0"
                                    }}
                                >
                                    Vui lòng đợi...
                                </span>
                            ) : (
                                <>
                                    <img
                                        src="/assets/icon/arrowPayment.svg"
                                        alt="arr"
                                    />
                                    Thanh Toán
                                </>
                            )}
                        </div>
                        <div
                            className="caculator-order d-flex align-items-center justify-content-center flex-column"
                            style={{
                                background:
                                    productInPosCartStore.dataListProInCart
                                        ?.items?.length > 0
                                        ? productInPosCartStore.dataListProInCart?.items?.every(
                                              (status: any) =>
                                                  status?.tmp_order_status ===
                                                  "1"
                                          ) && productInPosCartStore.dataListProInCart?.items?.every(
                                            (item: any) =>
                                                !item.edited
                                        )
                                            ? "#E7E9ED"
                                            : "#0e72ed"
                                        : "#E7E9ED",
                            }}
                            onClick={() => {
                                // Nếu tmp_order_status = 1 (tất cả món đã được order) return
                                if (
                                    productInPosCartStore.dataListProInCart?.items?.every(
                                        (status: any) =>
                                            status?.tmp_order_status === "1"
                                    ) && productInPosCartStore.dataListProInCart?.items?.every(
                                        (item: any) =>
                                            !item.edited
                                    )
                                ) {
                                    return;
                                }

                                if (productInPosCartStore.isLoadingOrder) {
                                    return;
                                }

                                productInPosCartStore.saveOrderCart();
                            }}
                        >
                            <img src="/assets/icon/cooking.svg" alt="" />
                            {productInPosCartStore.isLoadingOrder ? "Vui lòng đợi.." : productInPosCartStore.dataListProInCart?.items?.some(
                                        (item: any) =>
                                            item.edited
                                    ) ? "Cập nhật" : "Order"}
                        </div>
                    </div>
                </div>
            </div>
            <ModalListCustomer />
            <ModalNoteCartItems />
        </div>
    );
};

export default observer(ProductInPosControl);
