import { HttpStatusCode } from "axios";
import { separationService } from "./SeparationService";
import { toastUtils } from "../../../../common/utils/Toastutils";
import {makeAutoObservable, toJS} from "mobx";
import StorageService from "../../../../common/service/StorageService";
import {productInPosCartStore} from "../../productInPos/ProductInPosCartStore";


class SeparationStore {
    listCartItems: any[] = [];
    listSplitOrder: any[] = [];
    isModalNoteOpen: boolean = false;
    isModalImageQrOpen: boolean = false;
    priceTotalPayment: number = 0; // Tổng tiền thanh toán
    remainingPayment: number = 0; // Tổng tiền còn lại sau khi thanh toán
    priceTotal: number = 0; // Tổng tiền đã thanh toán
    imageQr: string = ''; // QR code image
    idPayTypeActive:number = 0;
    listPayType: any = []
    notePos: string | null | undefined;
    imageBank: string | null | undefined;
    isSave: boolean = false;
    posid: string | undefined;
    roomid: string | null = "";
    floorid: string | null = "";
    type: string | null | undefined;
    selectedItems: number[] = [];
    localPaymentPrice = 0;
    dining_table_id: string | null | undefined;
    diningTableSession: string | undefined; // dining_tables_session
    diningTablePaySession: string | undefined; // dining_table_session
    isLoadingBtn: boolean = false;
    isLoading: boolean = false;


    staticDataTableSession: {
        dining_tables_session: string;
        row_ids: any;
        split_order_session: string;
        pos_id: string;
        preview: string;
        // dining_table_id: string;

    } = {
        dining_tables_session: '',
        row_ids: [],
        split_order_session: '',
        pos_id: '',
        preview: "",
        // dining_table_id: ""
    };

    staticDataPayment: {
        payment_price: number;
    } = {
        payment_price: 0
    };

    constructor() {
        makeAutoObservable(this);
        this.loadPaymentDataFromLocalStorage();
    }

    savePaymentPriceToLocalStorage = () => {
        const paymentData = {
            payment_price: this.staticDataPayment.payment_price,
            payment_total_price: this.priceTotalPayment,
            dining_tables_session: this.diningTableSession,
            split_order_session: this.staticDataTableSession.split_order_session,
            pos_id: this.staticDataTableSession.pos_id,
            remaining_payment: this.remainingPayment,

        };
        StorageService.setObjectStore(`payment_data/dining_tables_session${this.diningTableSession}`, paymentData);
    };


    loadPaymentDataFromLocalStorage = () => {
        const dining_tables_session = StorageService.getObjectStore(`cartPos${this.posid}-${this.dining_table_id}`);
        const key = `payment_data/dining_tables_session${dining_tables_session?.dining_table_session}`;
        const paymentData = StorageService.getObjectStore(key);

        if (paymentData) {
            this.staticDataPayment.payment_price = paymentData.payment_price || 0;
            this.diningTableSession = paymentData.dining_tables_session || '';
            this.staticDataTableSession.split_order_session = paymentData.split_order_session || '';
            this.staticDataTableSession.pos_id = paymentData.pos_id || '';
            this.remainingPayment = paymentData.remaining_payment || 0;
            this.priceTotalPayment = paymentData.payment_total_price || 0;

        } else {
            console.warn("No payment data found in localStorage for the current diningTableSession.");
        }
    };

    showModalNote = () => {
        this.isModalNoteOpen = true;
    };

    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };

    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };

    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };

    handleRemoveImageBank = () => {
        this.imageBank = '';
    };

    toggleSelectItem(index:number) {
        if (this.selectedItems.includes(index)) {
            this.selectedItems = this.selectedItems.filter(i => i !== index);
        } else {
            this.selectedItems.push(index);
        }

        const selectedRowIds = this.selectedItems.map(i => this.listCartItems[i].rowId);
        this.staticDataTableSession.row_ids = selectedRowIds;
        const newTotalPrice = this.calculateTotalPrice(this.selectedItems);
        this.staticDataPayment.payment_price = newTotalPrice;
        this.remainingPayment = newTotalPrice;
        this.priceTotalPayment = newTotalPrice;
        this.localPaymentPrice = newTotalPrice;
    }

    // calculateTotalPrice(items = this.selectedItems) {
    //     return items.reduce((total, index) => {
    //         const item = this.listCartItems[index];
    //         return total + (item.qty * item.price);
    //     }, 0);
    // }
    calculateTotalPrice(items = this.selectedItems) {
        return items.reduce((total, index) => {
            const item = this.listCartItems[index];
            const discountMultiplier = 1 - (item.discount || 0) / 100; // Hệ số chiết khấu, nếu không có thì = 1
            const discountedPrice = item.qty * item.price * discountMultiplier; // Tính giá trị sau chiết khấu
            return total + discountedPrice; // Cộng vào tổng giá trị
        }, 0);
    }


    resetPaymentData = () => {
        this.listPayType = [];
        this.priceTotalPayment = this.staticDataPayment.payment_price;
        this.remainingPayment = this.priceTotalPayment;
        this.priceTotal = 0;
    };


    handlePushPayType = (type: number) => {
        const id = Date.now(); // `id` is now a number, compatible with `idPayTypeActive`
        const price = this.remainingPayment || 0;
        const data = {
            id: id,
            price: price,
            type: type,
            close: 1,
        }
        this.priceTotalPayment = this.staticDataPayment.payment_price
        this.listPayType.unshift(data);
        this.idPayTypeActive = id;
        this.handleMathPrice()
    };

    handleRemovePayType = (id: number) => {
        this.listPayType = this.listPayType.filter((item: any) => item.id !== id);
        this.handleMathPrice()
    };

    handleNumber = (idPayType:number, numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType) {
                if (item.close === 1) {
                    item.price = numberPay
                    item.close = 2
                } else
                    item.price =  parseInt( item.price + String(numberPay))
            }
            return item
        })
        this.handleMathPrice()
    }

    // Error chua xoa theo tung ki tu
    handleRemove = (idPayType: number) => {
        this.listPayType = this.listPayType.map((item: any) => {
            if(item.id === idPayType) {
                // Xoa theo tung ki tu tu duoi len tren
                if (item.price.length > 1) {
                    item.price = item.price.slice(0, -1);
                } else {
                    item.price = '0';
                }
            }
            return item;
        });
        this.handleMathPrice()
    }

    handleMathPrice = () => {
        const totalPaid = this.listPayType.reduce((total: number, item: any) => {
            return total + (item.price ? parseFloat(item.price) : 0); // Đảm bảo giá trị price là số
        }, 0);

        this.priceTotal = totalPaid;

        if (totalPaid > this.priceTotalPayment) {
            this.remainingPayment = 0;
        } else {
            this.remainingPayment = this.priceTotalPayment - totalPaid;
        }

        if (this.remainingPayment < 0) {
            this.remainingPayment = 0;
        }

    };

    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = this.listPayType
                .filter((item: any) => item.type === 2)
                .reduce((total: number, item: any) => total + (item.price || 0), 0);
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await separationService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            } else{
                this.showModalImageQr()
            }
        }
    }

    async fetchSaveOrderPosFnb (navigate: (route: string) => void) {
        if(this.isSave === false) {
            this.isSave = true

            const customer_id = productInPosCartStore?.dataCustomer?.id ? productInPosCartStore.dataCustomer.id : null
            const bank_amount = this.listPayType
                .filter((item: any) => item.type === 2)
                .reduce((total: number, item: any) => total + (item.price || 0), 0);

            const cash_amount = this.listPayType
                .filter((item: any) => item.type === 1)
                .reduce((total: number, item: any) => total + (item.price || 0), 0);

            const params = {
                type: "split_order",
                dining_table_session: StorageService.getObjectStore(`cartPos${this.staticDataTableSession.pos_id}-${this.dining_table_id}`)?.dining_table_session,
                dining_table_data: this.staticDataTableSession.split_order_session,
                pos_id: this.staticDataTableSession.pos_id,
                session_data: {
                    customer_id: customer_id,
                    bank_amount: bank_amount,
                    cash_amount: cash_amount,
                    image_bank: this.imageBank,
                    note: this.notePos,
                    wallet_amount: 0,
                }
            }

            const result = await separationService.fetchCheckoutPayment(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                StorageService.removeLocalStore(`uploadedImageBank/dining_table_session${this.diningTablePaySession}`);
                StorageService.removeLocalStore(`payment_data/dining_tables_session${this.diningTableSession}`)
                StorageService.removeLocalStore(`cartPos${this.posid}-${this.dining_table_id}`)
                // StorageService.removeLocalStore(`dining_table_session/posId${StorageService.getLocalStore(`posID`)}`)
                // StorageService.removeLocalStore(`remaining_payment`)
                StorageService.removeLocalStore(`uploadedImageBank/dining_table_session${this.diningTablePaySession}`);
                this.listPayType = []
                this.handleCancelImageQr()
                this.notePos = ''
                navigate(`/pos/${this.staticDataTableSession.pos_id}/tach-don/thanh-cong/${this.floorid}/${this.roomid}/${this.dining_table_id}/${result.body.id}`);
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');

            }
        }
    }

    async fetchCartItems() {
        if(this.isLoading === false) {
            this.isLoading = true;
            const dining_tables_session = this.diningTableSession ?? '';
            const result = await separationService.fetchCartItems(dining_tables_session);

            if (result.status === HttpStatusCode.Ok) {
                this.isLoading = false;
                this.listCartItems = result.body;
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }

    async splitOrder(data: any) {
        if (this.isLoadingBtn === false) {
            this.isLoadingBtn = true;
            const {row_ids} = this.staticDataTableSession;
            const dining_tables_session = this.diningTableSession ?? '';
            const splitOrderProduct = {
                dining_tables_session,
                row_ids
            };
            const result = await separationService.splitOrder(splitOrderProduct);
            if (result.status === HttpStatusCode.Ok) {
                this.isLoadingBtn = false;
                this.listSplitOrder = result.body;
                toastUtils.success('Tách đơn hàng thành công!', '');
                this.staticDataTableSession.split_order_session = result.body.split_order_session
                return result.body.split_order_session;
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }


}

export const separationStore = new SeparationStore();
