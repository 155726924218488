import {makeAutoObservable} from "mobx";
import {dashboardService} from "../../dashboard/DashboardService";
import {settingQrcodeService} from "../settingQrcode/SettingQrcodeService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {processingRequestsService} from "./ProcessingRequestsService";
import {number, string} from "yup";
import StorageService from "../../../common/service/StorageService";
interface StaticParam {
    page: number | 0;
    per_page: number;
}
class ProcessingRequestsStore{
    isLoaddingFloorRoom: boolean = false
    isModalOpen: boolean = false
    isModalOpenSelectMachinePos: boolean = false
    isModalOpenOrder: boolean = false
    isLoading: boolean = false
    isSave: boolean = false
    isRequest: string = 'ALL'
    statusRequest: string = 'PENDING,READY,PROCESSING,PREPARING'
    listStore: any[] = [] // Danh sách địa điểm
    locationIdActive: number | undefined // id địa điểm active
    floorRoomIdActive: number | undefined // id tầng/phòng active
    listFloorRoom: any[] = [] // Danh sách tầng phòng
    listPos: any[] = [] // Danh sách mày Pos theo địa điểm
    filterFloorRooms: any[] = [] // Danh tầng phòng lọc theo địa điểm
    listRequest : any[] = [] // Danh sách yêu cầu
    reasonRefusal:string = 'REASON_NO_CUSTOMER' // Lý do từ chối
    textReasonRefusalOther:string = '' // Lý do từ chối
    pos_id:number | undefined // Máy Pos
    paramsModal:any = {
        id: string,
        type: string
    }
    metadata: any = {
        count_request: null,
        total: null,
        page: 1,
        total_page: null,
        size: null,
    };

    initParam(){
        const queryParam = new URLSearchParams(window.location.search)
        this.staticParam.page = queryParam.get('page') ? Number(queryParam.get('page')) - 1 : 0;
    }
    staticParam: StaticParam = {
        page: 0,
        per_page: 20,
    };
    constructor() {
        makeAutoObservable(this)
        this.initParam()
    }
    async fetchDataStore() {
        // this.isLoading = true;
        try {
            const result = await dashboardService.fetchDataStore();
            this.listStore = result.body.data ?? [];
            this.locationIdActive = this.listStore[0].id
        } catch (error) {
            console.error("Error fetching revenue data:", error); // Xử lý lỗi nếu có
        } finally {
            // this.isLoading = false;
        }
    }

    async fetchListFloorRoom() {
        const result = await settingQrcodeService.fetchListFloorRoom();
        this.isLoaddingFloorRoom = true
        if (result.status === HttpStatusCode.Ok) {
            this.listFloorRoom = result.body;
            const dataListFloorRoom =
                processingRequestsStore.listFloorRoom?.length > 0 ?
                    processingRequestsStore.listFloorRoom?.filter((itemFilter:any) => itemFilter.location_id === processingRequestsStore.locationIdActive).map((item: any) => ({
                        id: item.id,
                        name: `${item.parent_id > 0 ? ``: ""} ${item?.name}`,
                        parent_id: item.parent_id,
                        total_request: item.total_request,
                    })) : [];
            processingRequestsStore.filterFloorRooms = dataListFloorRoom
            if(processingRequestsStore.filterFloorRooms[0]){
                processingRequestsStore.floorRoomIdActive = processingRequestsStore.filterFloorRooms[0].id
                processingRequestsStore.fetchGetOrderRequest().then();
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async fetchListFloorRoomLocation() {
        const result = await settingQrcodeService.fetchListFloorRoom();
        if (result.status === HttpStatusCode.Ok) {
            this.listFloorRoom = result.body;
            const dataListFloorRoom =
                processingRequestsStore.listFloorRoom?.length > 0 ?
                    processingRequestsStore.listFloorRoom?.filter((itemFilter:any) => itemFilter.location_id === processingRequestsStore.locationIdActive).map((item: any) => ({
                        id: item.id,
                        name: `${item.parent_id > 0 ? ``: ""} ${item?.name}`,
                        parent_id: item.parent_id,
                        total_request: item.total_request,
                    })) : [];
            processingRequestsStore.filterFloorRooms = dataListFloorRoom
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchListPos() {
        const params = {
            store_id: this.locationIdActive,
            per_page: 100,
            status: 1,
        }
        const result = await processingRequestsService.fetchListPos(params);
        if (result.status === HttpStatusCode.Ok) {
            this.listPos = result.body.data
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async fetchGetOrderRequest() {
        this.isLoading = true
        const params = {
            page: this.staticParam.page + 1,
            per_page: this.staticParam.per_page,
        }
        const result = await processingRequestsService.fetchGetOrderRequest(this.locationIdActive,this.floorRoomIdActive,this.isRequest,this.statusRequest,params);
        this.isLoading = false
        if (result.status === HttpStatusCode.Ok) {
            this.listRequest = result.body.data
            this.metadata = {
                count_request: result.body.metadata.count_request,
                total: result.body.metadata.total,
                page: result.body.metadata.page,
                total_page: result.body.metadata.total_page,
                size: result.body.metadata.size,
            };
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    async fetchApproveRequest(idRequest:string,typeRequest:string,status:string) {
        if(this.isSave === false){
            this.isSave = true
            let params
            if(status === 'COMPLETED'){
                params = {
                    status: status,
                    type_request: typeRequest,
                };
            }else if(typeRequest === 'ORDER'){
                const dataDiningTable = this.listRequest.find((item:any) => item.id === idRequest)
                params = {
                    status: status,
                    type_request: typeRequest,
                    reason_refusal: this.reasonRefusal,
                    reason_refusal_other_text: this.reasonRefusal === 'REASON_OTHER' ? this.textReasonRefusalOther : '',
                    request_content_client: {
                        cart_items: dataDiningTable.request_content_client.cart_items
                    }
                }
            }else{
                params = {
                    status: status,
                    type_request: typeRequest,
                    reason_refusal: this.reasonRefusal,
                    reason_refusal_other_text: this.reasonRefusal === 'REASON_OTHER' ? this.textReasonRefusalOther : '',
                }
            }
            const result = await processingRequestsService.fetchApproveRequest(params,idRequest);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.fetchListFloorRoomLocation()
                if(typeRequest === 'CALL_STAFF'){
                    this.listRequest = this.listRequest.filter((item:any) => item.id !== idRequest)
                    toastUtils.success("Phục vụ thành công", "");
                    this.handleCancel()
                }else{
                    this.listRequest = this.listRequest.filter((item:any) => item.id !== idRequest)
                    toastUtils.success("Hủy thành công", "");
                    this.handleCancel()
                }
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }

    //-------------Modal Cancel-------------
    showModal = () => {
        this.isModalOpen = true
    };

    handleOk = () => {
        this.isModalOpen = false
    };

    handleCancel = () => {
        this.isModalOpen = false
    };
    //-------------End Modal Cancel-------------

    //-------------Modal Order-------------
    showModalOrder = () => {
        this.isModalOpenOrder = true
    };
    handleOkOrder = () => {
        this.isModalOpenOrder = false
    };
    handleCancelOrder = () => {
        this.isModalOpenOrder = false
    };
    async fetchApproveOrderRequest(idRequest:any,navigate: (route: string) => void) {
        if(this.checkValidatePosID()){
            toastUtils.warning('Vui lòng chọn đầy đủ thông tin!','')
            return false;
        }
        const dataDiningTable = this.listRequest.find((item:any) => item.id === idRequest)
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                pos_id: this.pos_id,
                status: 'COMPLETED',
                type_request: 'ORDER',
                request_content_client: {
                    cart_items: dataDiningTable.request_content_client.cart_items,
                    note_order: dataDiningTable.request_content_client.note_order ? dataDiningTable.request_content_client.note_order : '',
                    discount: '0',
                    promotion: '0',
                    taxes_fees: '0',
                    total_amount: '10'
                }
            }
            const result = await processingRequestsService.fetchApproveRequest(params,idRequest);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                let storedCart = StorageService.getObjectStore(`cartPos${this.pos_id}-${dataDiningTable.table_id}`);
                if(storedCart){
                    result.body.orderSave.forEach((item:any) => {
                        const options = JSON.parse(item.options);
                        const itemPush = {
                            product_id: item.product_id,
                            productName: item.product_name,
                            variant: {
                                id: options.variant.id,
                                name: options.variant.attribute_compare,
                            },
                            idData: item.id,
                            quantity: item.qty,
                            price: item.price,
                            discount: item.discount,
                            unit: options.unit,
                            sku: options.sku,
                            note: item.note,
                            tmp_order_status: item.tmp_order_status,
                            edited: false,
                        }
                        storedCart.items.unshift(itemPush)
                    })
                    StorageService.setObjectStore(`cartPos${this.pos_id}-${dataDiningTable.table_id}`,storedCart)
                }
                this.fetchListFloorRoomLocation()
                const dataFloorRoom = this.listFloorRoom.find((item:any) => item.id === this.floorRoomIdActive)
                let floorId = dataFloorRoom.parent_id > 0 ? dataFloorRoom.parent_id : dataFloorRoom.id
                let roomId = dataFloorRoom.parent_id > 0 ? dataFloorRoom.id : 0
                this.isModalOpenOrder = false
                navigate(`/pos/${this.pos_id}/san-pham/${floorId}/${roomId}/${dataDiningTable.table_id}`) //:posId/san-pham/:floorId/:roomId/:diningTableId
                this.pos_id = undefined
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }
    //-------------End Modal Order-------------

    //-------------Modal Select Machine Pos-------------
    showModalSelectMachinePos = () => {
        this.isModalOpenSelectMachinePos = true
    };
    handleOkSelectMachinePos = () => {
        this.isModalOpenSelectMachinePos = false
    };
    handleCancelSelectMachinePos = () => {
        this.isModalOpenSelectMachinePos = false
    };

    errors: any = {
        pos_id: '',
    }
    clearError() {
        this.errors = {
            pos_id: "",
        };
    }
    checkValidatePosID = () => {
        this.clearError();
        let pos_id = this.pos_id;
        if(!pos_id ){
            this.errors.pos_id = "Vui lòng chọn máy Pos!";
            return true
        }
        return false
    }
    async fetchApprovePayRequest(idRequest:any,statusPay:string,navigate: (route: string) => void) {
        if(this.checkValidatePosID()){
            toastUtils.warning('Vui lòng chọn đầy đủ thông tin!','')
            return false;
        }
        if(this.isSave === false){
            this.isSave = true;
            const params = {
                pos_id: this.pos_id,
                status: statusPay,
                type_request: 'PAY',
            }
            const result = await processingRequestsService.fetchApproveRequest(params,idRequest);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.fetchListFloorRoomLocation()
                const dataFloorRoom = this.listFloorRoom.find((item:any) => item.id === this.floorRoomIdActive)
                const dataDiningTable = this.listRequest.find((item:any) => item.id === idRequest)
                let floorId = dataFloorRoom.parent_id > 0 ? dataFloorRoom.parent_id : dataFloorRoom.id
                let roomId = dataFloorRoom.parent_id > 0 ? dataFloorRoom.id : 0
                this.isModalOpenSelectMachinePos = false
                navigate(`/pos/${this.pos_id}/san-pham/${floorId}/${roomId}/${dataDiningTable.table_id}`) //:posId/san-pham/:floorId/:roomId/:diningTableId
                this.pos_id = undefined
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }
    //-------------End Modal Select Machine Pos-------------
}

export const processingRequestsStore = new ProcessingRequestsStore()