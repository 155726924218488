import {HttpStatusCode} from "axios";
import {roomService} from "./RoomService";
import {toastUtils} from "../../common/utils/Toastutils";
import {makeAutoObservable, toJS} from "mobx";
import {posService} from "../settings/pos/PosService";

// interface StaticParamRoom {
//     offset: number | null;
//     order_by: string | null;
//     page: number;
//     parent_id: number | null;
//     per_page: number | null;
//     search: string | null;
//     status: string | null;
//     store_id: string | null;
// }

class RoomStore {
    listRoom: any[] = [];
    listRoomAll: any[] = [];
    listStore: any[] = [];
    loadingDataAll: boolean = true;
    loadingDataTable: boolean = true;
    loadingListStore: boolean = true;
    isCreating: boolean = false;
    modalName: string = '';
    modalOpen: boolean = false;
    waitingText: string = '';
    selectedRoomInTable: any[] = [];
    neededActionRoomValue: string = '';
    locationFilterValue: number = -1;
    roomFilterValue: number = -1;
    dataDetail: any;
    isLoading = false;
    isSave: boolean = false;
    isLoadingBtn = false;
    allowSearch:boolean = false
    searchTemp: string = '';

    // staticParamRoom: StaticParamRoom = {
    //     offset: 0,
    //     order_by: "id",
    //     page: 0,
    //     parent_id: null,
    //     per_page: 10,
    //     search: "",
    //     status: "",
    //     store_id: "",
    // }

    staticParamRoom :{offset: number, order_by: string, parent_id: string|null, page: number, per_page: number, search: string|null, status: string|null, store_id: number | null} = {
        offset: 0,
        order_by: "id",
        page: 0,
        parent_id: "",
        per_page: 100,
        search: "",
        status: "",
        store_id: 0,
    }


    staticDataRoom: {
        id: string,
        name: string,
        store_id: string,
        parent_id: number,
        ordering: number,
        status: number,
        // parent:any,
    } = {
        id: '',
        name: '',
        ordering: 0,
        parent_id: 0,
        store_id: '',
        status: 0,
        // parent: ''
    }

    metadata: { total: number, page: number, total_page: number, size: number } = {
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamRoom.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamRoom.store_id = query.get("store_id")
            ? Number(query.get("store_id"))
            : null;
        this.staticParamRoom.parent_id = query.get("parent_id")
            ? String(query.get("parent_id"))
            : null;
        // this.staticParamRoom.search = query.get("search")
        //     ? String(query.get("search"))
        //     : "";
    }

    async getRoomAll(run?:boolean) {
        if (this.listStore.length < 1 || run) {
            this.loadingDataAll = true;
            this.loadingDataTable = false;
        }
        let {
            offset,
            order_by,
            page,
            parent_id,
            per_page,
            search,
            status,
            store_id
        } = this.staticParamRoom;
        const paramRoom = {
            offset: offset,
            order_by: order_by,
            page: page + 1,
            parent_id: parent_id,
            per_page: per_page,
            search: search,
            status: status,
            store_id: store_id
        }
        const result = await roomService.fetchRoomGettingAll(paramRoom);
        if (result.status === HttpStatusCode.Ok) {
            this.listRoom = result.body.data;
            this.metadata = result.body.metadata;
            this.loadingDataAll = false;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

    async getStoreAll() {
        if (this.listStore.length < 1) {
            this.loadingListStore = true;
        }
        const result = await posService.fetchStoreGettingAll();
        this.loadingListStore = false;
        if (result.status === HttpStatusCode.Ok) {
            if (this.listStore != result.body.data) {
                this.listStore = result.body.data;
            }
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '')
        }

    }

    async getRoomWithRequirement(run?: boolean) {
        if (this.listRoom.length < 1 || run) {
            this.loadingDataTable = true;
        }
        this.loadingDataTable = true;
        let {
            search,
            page,
            per_page,
            store_id,
            parent_id
        } = this.staticParamRoom;
        const paramRoom = {
            search: search ? search : '',
            page: page + 1,
            per_page: per_page ? per_page : 10,
            store_id: store_id ? store_id : '',
            parent_id: parent_id ? parent_id : ''
        }
        const result = await roomService.fetchRoomWithRequirement(paramRoom);
        this.loadingDataTable = false;
        if (result.status === HttpStatusCode.Ok) {
            if (this.listRoom != result.body.data) {
                this.listRoom = result.body.data
                this.metadata = result.body.metadata
            }
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '')
        }
    }

    async getRoomAllFloor() {
        const result = await roomService.fetchRoomGettingAllFloor();
        if (result.status === HttpStatusCode.Ok) {
            this.listRoomAll = result.body;
            this.loadingDataAll = false;
        } else {
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

    async deleteRoom() {
        let {id} = this.staticDataRoom;
        const result = await roomService.fetchRoomDeleting(this.staticDataRoom.id);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Xóa thành công!', '');
            this.listRoom = this.listRoom.filter((itemData:any) => itemData.id != id)
            if(this.listRoom.length == 0) {
                this.getRoomWithRequirement();
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }

    async addRoom(form: any):Promise<any> {
        this.isLoadingBtn = true;
        if (this.isSave === false) {
            this.isSave = true
            let {name, store_id, parent_id, ordering, status} = this.staticDataRoom;
            const addDataRoom = {
                name: name,
                store_id: store_id,
                ordering: ordering,
                parent_id: parent_id,
                status: 1,
            }
            const result = await roomService.fetchRoomAdding(addDataRoom)
            this.isLoadingBtn = false;
            this.isSave = false;
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success('Thêm thành công!', '');
                this.listRoom.unshift(result.body.data)
                if(this.listRoom.length >= this.staticParamRoom.per_page){
                    this.listRoom.pop()
                }
                this.modalOpen = false;
                form.resetFields()
            } else {
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }

    async editRoom() {
        this.isLoadingBtn = true;
        this.waitingText = 'Vui lòng chờ...'
        let {id, name, store_id, parent_id, ordering} = this.staticDataRoom;
        const editDataRoom = {
            id: id,
            name: name,
            store_id: store_id,
            ordering: ordering,
            parent_id: parent_id,
            status: '1'
        }
        const result = await roomService.fetchRoomEditing(editDataRoom);
        this.isLoadingBtn = false;
        this.waitingText = '';
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Sửa thành công!', `${id}`);
            const updatedRoomData = result.body;
            this.modalOpen = false;
            this.listRoom = this.listRoom.map((itemData:any) => {
                if(itemData.id == this.staticDataRoom.id){
                    itemData.name = updatedRoomData.name;
                    itemData.store_id = parseInt(updatedRoomData.store_id);
                    itemData.parent_id = updatedRoomData.parent_id;
                    itemData.ordering = updatedRoomData.ordering;
                    itemData.status = updatedRoomData.status;
                    itemData.parent = updatedRoomData.parent;
                }
                return itemData;
            })
            this.isSave = false
            this.handleClearContent()
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
    }

    async handleRoomActionFilter() {
        const neededActionRoomList = this.listRoom.filter((item: any) =>
            this.selectedRoomInTable.includes(item.id)
        );
        if (neededActionRoomList.length === 0) {
            toastUtils.warning('Vui lòng chọn phòng cần xóa!', '');
            return;
        }

        if (this.neededActionRoomValue === 'delete') {
            await Promise.all(
                neededActionRoomList.map(item => {
                    this.staticDataRoom.id = item.id;
                    return this.deleteRoom();
                })
            );
        } else {
            toastUtils.error('Vui lòng chọn hành động hợp lệ!', '');
        }

        this.neededActionRoomValue = '';
    }

    async handleCheckOnLocationOption(value: any) {
        value = Number(value)
        if (this.locationFilterValue === value) {
            this.locationFilterValue = -1;
            this.staticParamRoom.store_id = null;
        } else {
            this.locationFilterValue = value;
            this.staticParamRoom.store_id = value;
        }

        this.getRoomWithRequirement();
    }

    async handleCheckOnRoomOption(value: any) {
        value = Number(value)
        if (this.roomFilterValue === value) {
            this.roomFilterValue = -1;
            this.staticParamRoom.parent_id = null;
        } else {
            this.roomFilterValue = value;
            this.staticParamRoom.parent_id = value;
        }
        await this.getRoomWithRequirement();
    }

    handleClearContent() {
        this.staticDataRoom = {
            id: '',
            name: '',
            ordering: 1,
            parent_id: 0,
            store_id: '',
            status: 0,
            // parent:'',
        };
        this.dataDetail = null
        this.modalName = '';
    }
}

export const roomStore = new RoomStore();