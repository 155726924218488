import {makeAutoObservable} from "mobx";
import {settingService} from "./SettingService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";


class SettingStore{
    isLoading: boolean = false
    isSave: boolean = false
    params: any[] = []
    bannerKey: string = ''
    constructor() {
        makeAutoObservable(this)
    }
    async fetchGetInfoPage(){
        const result = await settingService.fetchGetInfoPage();
        if (result.status === HttpStatusCode.Ok) {
            this.params = result.body[0].items
            this.bannerKey = result.body[0].items.find((item:any) => item.setting_key === 'banner_key').setting_value
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchUpdateInfoPage(){
        this.isSave = true
        if(this.isSave === true){
            const result = await settingService.fetchUpdateInfoPage(this.params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Cập nhật thành công", "");
                this.params = result.body[0].items
                this.bannerKey = result.body[0].items.find((item:any) => item.setting_key === 'banner_key').setting_value
            }else{
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }

    }
    handleClosePreview = () => {
        this.bannerKey = "";
        settingStore.params.find((item:any) => item.setting_key === 'banner_key').setting_value = ''
    };
    changeFilePreview = (pathFile: string) => {
        this.bannerKey = pathFile;
        settingStore.params.find((item:any) => item.setting_key === 'banner_key').setting_value = pathFile
    };
}

export const settingStore = new SettingStore()