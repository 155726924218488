import {makeAutoObservable} from "mobx";
import {paymentService} from "../../paymentPos/PaymentService";
import {HttpStatusCode} from "axios";
import {productInPosCartStore} from "../../productInPos/ProductInPosCartStore";
import StorageService from "../../../../common/service/StorageService";
import {toastUtils} from "../../../../common/utils/Toastutils";
import {refundOrderService} from "../RefundOrderService";
import {refundPaymentService} from "./RefundPaymentService";


class RefundPaymentStore{
    isSave: boolean = false
    isLoading: boolean = false
    posid: string | undefined
    diningTableId: string | undefined = ""
    transactionId: any
    isModalNoteOpen: boolean = false
    isModalImageQrOpen: boolean = false
    priceTotal: number = 0 // Tổng tiền thanh toán
    priceTotalAfterPayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    pricePayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    imageQr: string = '' // Tổng tiền còn lại sau khi thanh toán
    idPayTypeActive:number = 0;
    listPayType: any = []
    listCart: any[] = []
    dataCustomer: any
    notePos: string | null | undefined;
    imageBank: string | null | undefined ;


    constructor() {
        makeAutoObservable(this)
    }
    showModalNote = () => {
        this.isModalNoteOpen = true;
    };
    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };

    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };
    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };
    handleRemoveImageBank = () => {
        this.imageBank = '';
    };

    /**
     *
     * @param type // 1: là tiền mặt. 2: là chuyển khoản
     * @description Thêm kiểu thanh toán
     */
    handlePushPayType = (type:number) => { // 1: là tiền mặt. 2: là chuyển khoản
        let idPayType = Date.now();
        const data = {
            id : idPayType,
            price: this.priceTotalAfterPayment,
            type: type,
            close: 1, // 1 là xóa hết 1 lần, 2 là xóa lần lượt
        }
        this.idPayTypeActive = idPayType;
        this.listPayType.unshift(data)
        this.handlePricePayment()
    };
    /**
     *
     * @param id
     * @description Xóa kiểu thanh toán
     */
    handleRemovePayType = (id:number) => {
        this.listPayType = this.listPayType.filter((item:any) => item.id != id)
        this.idPayTypeActive = this.listPayType[0]?.id ? this.listPayType[0]?.id : 0;
        this.handlePricePayment();
    };

    /**
     * @description Tổng tiền thanh toán
     */
    handlePricePayment = () => {
        this.pricePayment = 0
        this.listPayType.forEach((item:any) =>{
            this.pricePayment += item.price
        })
        if(this.priceTotal - this.pricePayment > 0){
            this.priceTotalAfterPayment = this.priceTotal - this.pricePayment;
        }else{
            this.priceTotalAfterPayment = 0;
        }

    }
    /**
     *
     * @param idPayType
     * @param numberPay
     * @description Thao tác bàn phím
     */
    handleNumber = (idPayType:number,numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                if(item.close === 1){
                    item.price = numberPay;
                    item.close = 2;
                }else{
                    item.price = parseInt(String(item.price) + String(numberPay));
                }
            }
            return item;
        })
        this.handlePricePayment();
    }
    handleRemove = (idPayType:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                item.price = 0;
                item.close = 2;
            }
            return item;
        })
        this.handlePricePayment();
    }

    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await paymentService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            } else{
                this.showModalImageQr()
            }
        }
    }
    async fetchSaveOrderPosEcommerce(navigate: (route: string) => void){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            var cash_amount = 0;
            var customer_id =  this?.dataCustomer?.id ? this.dataCustomer.id : null

            this.listPayType.filter((item:any) => item.type === 1).map((item:any) => cash_amount += item.price)
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)

            const params = {
                transaction_pos_id: this.transactionId,
                note_pay: this.notePos,
                customer_id: customer_id,
                price_cash: cash_amount,
                price_bank: bank_amount,
                total_amount: this.priceTotal,
                transfer_image: this.imageBank,
                cart_items:this.listCart.map((item:any) =>
                    {
                        return {
                            product_id: item.productId,
                            qty: item.quantity,
                            variant_id: item.variant.id,
                            discount: item.discount,
                            price: item.price
                        }
                    }
                )
            }
            this.isLoading = true;
            const result = await refundPaymentService.fetchSaveOrderRefund(params);
            this.isLoading = false;
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                StorageService.removeLocalStore(`refundOrderId${this.posid}`)
                StorageService.removeLocalStore(`refundPos${this.posid}-${this.diningTableId}`)
                StorageService.removeLocalStore(`refundNote${this.posid}`)
                StorageService.removeLocalStore(`refundCustomer${this.posid}`) 
                this.listPayType = []
                this.dataCustomer = null
                this.notePos = ''
                this.listCart = []
                navigate(`/pos/${this.posid}/thanh-cong/${result.body.id}`);
            }
            else{
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }

        // this.openModal = false;
    }
}

export const refundPaymentStore = new RefundPaymentStore()