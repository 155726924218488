import React, { useEffect } from "react";
import "./styles/RefundOrderDetailsPage.scss";
import HeaderProductInPos from "../productInPos/components/headerProductInPos/HeaderProductInPos";
import LayoutRefundOrder from "./components/layout/LayoutRefundOrder";
import RefundOrderControl from "./components/refundOrderControl/RefundOrderControl";
import { observer } from "mobx-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { refundOrderStore } from "./RefundOrderStore";
import { utils } from "../../../common/utils/Utils";
import ReactPaginate from "react-paginate";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
const RefundOrderDetailsPage = () => {
  const { posId,orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { diningTableId } = useParams<{ diningTableId: string }>();
  const { floorId } = useParams<{ floorId: string }>();
  const { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {
    refundOrderStore.posIdLC = posId;
    refundOrderStore.idOrder = Number(orderId);
    refundOrderStore.floorId = floorId;
    refundOrderStore.roomId = roomId;
    refundOrderStore.diningTableId = diningTableId;
  }, [orderId, posId]);


  useEffect(() => {
    refundOrderStore.dataFilterRefundOrder.posId = posId;
    refundOrderStore.fetchAllRefundOrder().then();
  }, [posId]);

  useEffect(() => {
    refundOrderStore.fetchDetailsRefundOrder();
  }, [refundOrderStore.idOrder]);

  // useEffect(() => {
  //   refundOrderStore.fetchDetailsRefundOrder();
  // }, [refundOrderStore.idOrder,orderId]);
  const handleChangePage = (e: any) => {
    refundOrderStore.staticParamRefundOrder.page = e.selected;
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(
      "page",
      (refundOrderStore.staticParamRefundOrder.page + 1).toString()
    );
    navigate({
      search: searchParams.toString(),
    });

    refundOrderStore.fetchAllRefundOrder();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="RefundOrderDetailsPage">
      <HeaderProductInPos search={false} />
      <LayoutRefundOrder
        onClickBack={() => navigate(`/pos/${posId}/hoan-tien/${floorId}/${roomId}/${diningTableId}`)}
      >
        <div className="RefundOrderDetailsPage-container">
          <div className="RefundOrderDetailsPage-orders">
            <div className="heightTable">
              <div className="table-container">
                <div className="table-responsive">
                  <table className="table table-striped" id="table-2">
                    <thead className="RefundOrderDetailsPage-thead">
                      <tr>
                        <th
                          style={{
                            width: "15%",
                            textAlign: "left",
                          }}
                        >
                          Ngày
                        </th>
                        <th
                          style={{
                            width: "28%",
                            textAlign: "left",
                          }}
                        >
                          Hóa đơn
                        </th>
                        <th
                          style={{
                            width: "22%",
                            textAlign: "left",
                          }}
                        >
                          Điểm bán
                        </th>
                        <th
                          style={{
                            width: "23%",
                            textAlign: "left",
                          }}
                        >
                          Thu ngân
                        </th>
                        <th
                          style={{
                            width: "12%",
                            textAlign: "left",
                          }}
                        >
                          Trạng thái đơn
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {refundOrderStore.isLoading ? (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center",pointerEvents: "none" }}>
                            <Loading />
                          </td>
                        </tr>
                      ) : refundOrderStore.dataRefundOrders.length <= 0 ? (
                        <tr>
                          <td colSpan={5} style={{ pointerEvents: "none" }}>
                            {" "}
                            <NoContent />{" "}
                          </td>
                        </tr>
                      ) : (
                        refundOrderStore.dataRefundOrders.length > 0 &&
                        refundOrderStore.dataRefundOrders.map((item) => (
                          <tr
                            className={`${
                              refundOrderStore.idOrder === item.id
                                ? "active"
                                : ""
                            }
                            `}
                            key={item.id}
                            onClick={() => {
                              refundOrderStore.idOrder = item.id
                              navigate(`/pos/${posId}/hoan-tien/${item.id}/${floorId}/${roomId}/${diningTableId}`)
                            }}
                          >
                            <td>
                              <div className="d-flex flex-column align-items-lg-start gap-1">
                                {new Date(item.created_at).toLocaleDateString(
                                  "en-GB"
                                )}
                                <span>
                                  {new Date(item.created_at).toLocaleTimeString(
                                    "en-GB",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <p className="code_order">
                                  {item.transaction_code}
                                </p>
                                <p className="name_customer">
                                  {item.customer_id
                                    ? item.customer_info.name
                                    : "Khách lẻ"}
                                </p>
                                <p className="price">
                                  {utils.formatCurrency(item.amount_total)}
                                </p>
                              </div>
                            </td>
                            <td>{item.location_store_name}</td>
                            <td>
                              <div className="staff d-flex align-items-center gap-2">

                                <div style={{width: "24px", height: "24px"}}>
                                  <img style={{borderRadius: "50%", maxWidth: "100%"}}
                                       src={
                                         item?.user_info?.avatar
                                             ? item.user_info.avatar
                                             : "/assets/icon/icon_user.svg"
                                       }
                                       alt="img-default"
                                  />
                                </div>
                                {item.user_info?.name}
                              </div>
                            </td>
                            <td>
                              <div className="textSuccess">Hoàn Thành</div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              {refundOrderStore.metadata.total_page > 1 && (
                  <ReactPaginate
                      previousLabel={
                        <img
                      src="/assets/icon/fi-rr-angle-small-left.svg"
                      alt="right"
                    />
                  }
                  nextLabel={
                    <img
                      src="/assets/icon/fi-rr-angle-small-right.svg"
                      alt="right"
                    />
                  }
                  breakLabel={"..."}
                  pageCount={refundOrderStore.metadata.total_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  onPageChange={handleChangePage}
                  forcePage={refundOrderStore.staticParamRefundOrder.page}
                />
              )}
            </div>
          </div>
          <RefundOrderControl></RefundOrderControl>
        </div>
      </LayoutRefundOrder>
    </div>
  );
};

export default observer(RefundOrderDetailsPage);
