// Viết api
import {getRequest, putRequest} from "../../../common/helpers/RequestHelper";

class SettingService {
    apiGetInfoPage = `api/v1/qr_order_code/get_info_page`;
    apiUpdateInfoPage = `api/v1/qr_order_code/update_info_page`;
    public fetchGetInfoPage() {
        return getRequest(`${this.apiGetInfoPage}`);
    }
    public fetchUpdateInfoPage(params:any) {
        return putRequest(`${this.apiUpdateInfoPage}`,params);
    }
}

export const settingService = new SettingService()