import React, {useEffect} from "react";
import { observer } from "mobx-react";
import {processingRequestsStore} from "../ProcessingRequestsStore";
import {Form, Input, Modal, Radio} from "antd";
import InputForm from "../../../../shared/components/form/InputForm";

const ModalCancel = () => {
    return (
        <>
            <Modal title="Lý do từ chối" open={processingRequestsStore.isModalOpen}
                   onOk={processingRequestsStore.handleOk} onCancel={processingRequestsStore.handleCancel}
                   footer={null}
                   afterClose={()=>{
                       processingRequestsStore.reasonRefusal = 'REASON_NO_CUSTOMER'
                       processingRequestsStore.textReasonRefusalOther = ''
                   }}
                    >
                <div className="form-group form-group-radio-home">
                    <Radio.Group onChange={(e) => {
                        processingRequestsStore.reasonRefusal = e.target.value
                    }} value={processingRequestsStore.reasonRefusal}>
                        <Radio value={`REASON_NO_CUSTOMER`}>
                            Khách không ở quán
                            <span className={`noti`}>
                                Nếu chọn lý do 'Khách không ở quán', trong vòng 1 tiếng nữa khách sẽ không được yêu cầu thêm
                            </span>
                        </Radio>
                        <Radio value={'REASON_OTHER'}>
                            Lý do khác
                        </Radio>
                    </Radio.Group>
                </div>
                {processingRequestsStore.reasonRefusal === 'REASON_OTHER' && (
                    <div className='form-group form-group-input'>
                        <InputForm
                            value={processingRequestsStore.textReasonRefusalOther}
                            showCount={true}
                            placeholder={"Lý do khác"}
                            // isError={demoStore.errors.text_base}
                            maxLength={200}
                            onChange={(e: any) => {
                                processingRequestsStore.textReasonRefusalOther = e.target.value;
                            }}
                        />
                    </div>
                )}
                <div className={`btn-cancel`} onClick={() => {
                    processingRequestsStore.fetchApproveRequest(processingRequestsStore.paramsModal.id, processingRequestsStore.paramsModal.type, 'CANCELED')
                    // processingRequestsStore.fetchApproveRequest(item.id, 'CALL_STAFF', 'COMPLETED')
                }}>
                    <button>
                        Xác nhận
                    </button>
                </div>
            </Modal>
        </>
    )
}
export default observer(ModalCancel)