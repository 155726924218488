import React, {useEffect} from "react";
import { observer } from "mobx-react";
import './styles/index.css';
import {permissionsStore} from "../../settings/permissions/PermissionsStore";
import {Form, Input} from "antd";
import UploadFile from "../../../shared/components/form/UploadFile";
import {numberByDateNow} from "../../../common/utils/Utils";
import {settingStore} from "./SettingStore";

const SettingPage = () => {
    const [form] = Form.useForm();

    useEffect(() => {
        settingStore.fetchGetInfoPage();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            content_key: settingStore.params?.find((item:any) => item.setting_key === 'content_key')?.setting_value,
            sub_title_key: settingStore.params?.find((item:any) => item.setting_key === 'sub_title_key')?.setting_value,
            time_working_key: settingStore.params?.find((item:any) => item.setting_key === 'time_working_key')?.setting_value,

        });
    }, [settingStore.params, form]);
    return (
        <div className={`container`}>
            <div className={`main-setting-page`}>
                <p className={`title`}>Cấu hình</p>
                <Form
                    form={form}
                    onFinish={(values: any) => {
                        settingStore.params.find((item:any) => item.setting_key === 'content_key').setting_value = values.content_key
                        settingStore.params.find((item:any) => item.setting_key === 'sub_title_key').setting_value = values.sub_title_key
                        settingStore.params.find((item:any) => item.setting_key === 'time_working_key').setting_value = values.time_working_key
                        settingStore.fetchUpdateInfoPage()
                    }}
                >
                    <div className={`content-setting-page`}>
                        <div className={`item-setting-page`}>
                            <div className={`left-item-setting-page`}>
                                <p className={`title-item`}>Thiết lập thông tin trang ban đầu</p>
                                <p className={`summary-item`}>Thông tin hiện thị màn đầu tiên khi khách truy cập vào
                                    trang bán hàng</p>
                            </div>
                            <div className={`right-item-setting-page`}>
                                <Form.Item
                                    className="item-form"
                                    label="Tiêu đề lời chào"
                                    name="content_key"
                                    rules={[{message: "Vui lòng nhập"}, {
                                        max: 200,
                                        message: "Không được quá 200 ký tự"
                                    }]}
                                >
                                    <Input placeholder="Vui lòng nhập"/>
                                </Form.Item>
                                <Form.Item
                                    className="item-form"
                                    label="Nội dung lời chào"
                                    name="sub_title_key"
                                    rules={[{message: "Vui lòng nhập"}, {
                                        max: 200,
                                        message: "Không được quá 200 ký tự"
                                    }]}
                                >
                                    <Input placeholder="Vui lòng nhập"/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className={`item-setting-page`}>
                            <div className={`left-item-setting-page`}>
                                <p className={`title-item`}>Đầu trang bán hàng</p>
                                <p className={`summary-item`}>Thông tin hiện thị tại đầu trang</p>
                            </div>
                            <div className={`right-item-setting-page`}>
                                <UploadFile
                                    text={`thiết lập banner`}
                                    description={`Hình ảnh banner`}
                                    file={settingStore.bannerKey}
                                    onIconRemove={settingStore.handleClosePreview}
                                    // onFileChange={(e) => handleFile(e, settingStore.changeFilePreview)}
                                    onChooseImg={(url: string)=>{
                                        settingStore.bannerKey = url
                                        settingStore.params.find((item:any) => item.setting_key === 'banner_key').setting_value = url
                                    }}
                                    code={numberByDateNow()}
                                    onResetState={()=>{ settingStore.bannerKey = ""}}
                                />
                                <Form.Item
                                    className="item-form"
                                    label="Thời gian hoạt động"
                                    name="time_working_key"
                                    rules={[{message: "Vui lòng nhập"}, {
                                        max: 200,
                                        message: "Không được quá 200 ký tự"
                                    }]}
                                >
                                    <Input placeholder="Vui lòng nhập"/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className={`content-submitFormPermissions`}>
                        <div></div>
                        <button
                            className={`submitFormPermissions`}
                            type="submit"
                        >
                            {permissionsStore.isSave ? 'Đang lưu' : 'Lưu'}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
export default observer(SettingPage)